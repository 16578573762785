import React from 'react';
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import logisticBottom from "../../../Assets/Images/story/logisticBottom.svg";
import {
    Typography, Timeline, TimelineItem, TimelineConnector, TimelineHeader, TimelineIcon, TimelineBody
} from "@material-tailwind/react";
import {motion} from "framer-motion";
import bannersInventory from "../../../Assets/Images/banners/Inventory Management.webp";

export default function LogisticManagement() {
    const bannerHeader = "Optimize Edilmiş Stok Yönetimi";
    const bannerTxt = "Ra Digital'in stok yönetimi çözümleri ile tedarik zincirinizi optimize edin. Kapsamlı hizmetlerimiz, etkili lojistik sağlayarak ürünlerinizin her zaman doğru yerde ve doğru zamanda olmasını garanti eder.";
    return (
        <div>
            <ServiceDetailBanner bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={bannersInventory}/>
            <motion.section
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.6}}
                className="bg-gradient-to-r from-[#64748b] via-[#414e63] to-[#202c3e] py-8 rounded-md shadow-2xl mb-6">
                <div className="flex flex-col justify-center items-center gap-3 lg:px-6 px-2 max-w-6xl mx-auto">
                    <Typography color="white" variant="h3" className="text-center nbXs:text-center">
                        Lojistik Yönetim Yaklaşımımız
                    </Typography>
                    <Typography color="white" variant="h4"
                                className="text-center nbXs:text-center pb-3 lg:text-xl text-lg nbXs:text-base">
                        Ra Digital'in Lojistik Yönetim çözümleri ile tedarik zincirinizi optimize edin. Ürünlerinizin
                        doğru yerde ve doğru zamanda olmasını sağlamak için lojistiği optimize ediyoruz. Kapsamlı
                        hizmetlerimiz şunları içerir:
                    </Typography>
                </div>
            </motion.section>

            <section className="py-6 flex justify-center ">
                <div className="xl:w-[55%] lg:w-[50%] md:w-[65%] p-8 shadow-basicShadow">
                    <Typography color="white" variant="h4" className="nbXs:text-center pb-3">
                        Lojistik Yönetiminin Temel Özellikleri
                    </Typography>
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    1
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Stok Yönetimi Stratejileri
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Tedarik ve talebi dengelemek için stratejiler uygulayarak stok bulunabilirliğini
                                    sağlarken tutma maliyetlerini azaltın.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    2
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Sipariş Karşılamada Optimizasyon
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Teslimat sürelerini iyileştirerek müşteri memnuniyetini ve bağlılığını artırın,
                                    sipariş karşılamada optimizasyon ile.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    3
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Özelleştirilmiş Raporlama ve Analitik
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Sürekli iyileşme için lojistik performansında özelleştirilmiş raporlama ve analitik
                                    erişimi sağlayın.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                        Ra Digital'i Lojistik Yönetimi için Neden Tercih Etmelisiniz?
                    </Typography>
                    <div className="lg:px-6 lg:w-[55%] w-full">
                        <div className="flex flex-col justify-center items-center gap-3 w-full">
                            <Typography color="white" variant="h3"
                                        className="lg:block hidden text-center nbXs:text-center pb-3">
                                Ra Digital'i Lojistik Yönetimi için Neden Tercih Etmelisiniz?
                            </Typography>
                            <Typography color="white" variant="paragraph">
                                <ul className="list-disc">
                                    <li>
                                        Verimli Stratejiler: Tedarik ve talebi dengelemek için stok yönetimi
                                        stratejileri uygulayarak, tutma maliyetlerini azaltın.
                                    </li>
                                    <li>
                                        İyileştirilmiş Karşılama: Teslimat sürelerini iyileştirmek için sipariş
                                        karşılama işlemlerini optimize ederek, müşteri memnuniyetini ve bağlılığını
                                        artırın.
                                    </li>
                                    <li>
                                        Veri Odaklı Kararlar: Sürekli iyileşme için lojistik performansında
                                        özelleştirilmiş raporlama ve analitik erişimi sağlayarak, veri odaklı kararlar
                                        alın.
                                    </li>
                                </ul>
                            </Typography>
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-2/3 lg:max-h-[325x]">
                        <img src={logisticBottom} alt="Radigital Lojistik Yönetimi"
                             className="w-full max-h-full nbXs:max-h-[275px] object-cover drop-shadow-xl xl:object-cover object-contain"/>
                    </div>
                </motion.div>
            </section>
        </div>
    );
}
