import React from 'react';
import { FaInstagram, FaLinkedin, FaTwitter, FaTiktok} from "react-icons/fa6";
import {Link, useParams} from "react-router-dom";
import MainLayout from "../../../Layout/MainLayout";
import {Avatar, Typography} from "@material-tailwind/react";
import raLogo from "../../../Assets/Images/RaLogos/softLogo ico.png";
import blog1 from "../../../Assets/Images/blog/blog1.webp";
import blog2 from "../../../Assets/Images/blog/blog2.webp";
import blog3 from "../../../Assets/Images/blog/blog3.webp";
import blog4 from  "../../../Assets/Images/blog/googleAdsBlog.webp"
import blog5 from  "../../../Assets/Images/blog/googleAdsBlog2.webp"
export default function BlogPost() {
    let {id} = useParams();
    const blogData = [
        {
            id: 1,
            title: 'Amazon Reklamcılığının Temel İlkeleri: Son Rehberiniz',
            explanation: "Amazon reklamcılığı, e-ticaret başarısının önemli bir yönü olup, opti mal sonuçlar için temel prensiplere dikkat edilmesini gerektirir. Temelleri keşfetmeye ne dersiniz:",
            image: blog1,
            description: [
                {
                    title: "Amazon' un Reklam Türlerini Anlama",
                    content:
                        "Sponsorlu Ürünler, Sponsorlu Markalar ve Sponsorlu Görüntü Reklamları hakkında bilgi edinin. Her biri farklı amaçlar için hizmet verir ve müşteri yolculuğunun farklı aşamalarını hedefler.",
                },
                {
                    title: 'Anahtar Kelime Araştırması',
                    content:
                        'İzleyicinizin ürünlerini aramak için kullandığı ilgili terimleri belirlemek için kapsamlı bir anahtar kelime araştırması yapın. Kapsamlı bir iç görü sunan Amazon\'un kendi anahtar kelime araştırma aracı veya üçüncü taraf seçenekleri gibi araçlardan faydalanın.',
                },
                {
                    title: 'Optimize Edilmiş Ürün Listeleri',
                    content:
                        'Ürün listelerinizin, ilgili anahtar kelimelerle, çekici açıklamalarla, yüksek kaliteli resimlerle ve rekabetçi fiyatlarla optimize edildiğinden emin olun. Bu unsurlar doğrudan reklam performansını ve dönüşüm oranlarını etkiler.',
                },
                {
                    title: 'Bütçe Tahsisi',
                    content:
                        'Reklam bütçenizi iş hedeflerinize, ürün marjlarına ve rekabete dayalı olarak belirleyin. Bütçeleri stratejik olarak kampanyalar arasında dağıtarak ROI\'yi maksimize ederken karlılığı koruyun.',
                },
                {
                    title: 'Kampanya Yapılandırma',
                    content:
                        'Kampanyalarınızı ürün kategorilerine, performans hedeflerine veya hedeflenen anahtar kelimelere dayalı mantıklı yapılar halinde organize edin. Doğru kampanya yapılandırma, daha kolay yönetim ve optimizasyon sağlar.',
                },
                {
                    title: 'İzleme ve Optimizasyon',
                    content:
                        'Tıklama oranları, dönüşüm oranları ve ACoS (Reklam Maliyeti / Satış) gibi kampanya performansı metriklerini düzenli olarak izleyin. Performans verilerine dayanarak teklifleri, hedeflemeyi ve reklam yaratıcılarını optimize ederek kampanyaları iyileştirin.',
                },
                {
                    title: 'Rakip Analizi',
                    content:
                        "Rakiplerinizin reklam stratejilerini ve performansını takip edin. Fırsatları belirlemek ve rekabetçi kalmak için reklam yaratıcılarını, anahtar kelime hedeflemelerini ve fiyatlandırma stratejilerini analiz edin.",
                },
                {
                    title: 'Amazon DSP\' yi Kullanma',
                    content:
                        "Gelişmiş hedefleme ve yeniden hedefleme yetenekleri için Amazon'un Talep Tarafı Platformu'nu (DSP) kullanmayı düşünün. Amazon'un ekosistemi içinde değil, web genelinde hedefleme yeteneklerinden faydalanın.",
                },
            ],
            underText: "Amazon reklamcılığının temel bu yönlerini ustalaşarak ve endüstri trendlerinden haberdar olarak, Amazon platformunda görünürlüğünüzü artırabilir, satışları artırabilir ve sürdürülebilir büyüme elde edebilirsiniz.\n" +
                "Daha derinlemesine bilgi ve işletme ihtiyaçlarınıza özel stratejiler için bugün Amazon reklam uzmanlarımızla danışın!\n",
            type: 'Amazon',
            date: '01.09.2023',
        },
        {
            id: 2,
            title: 'Amazon Listeleme Performansınızı Artırın Bu SEO İpuçlarıyla',
            explanation: "Amazon' un rekabetçi ortamında, ürün listelerinizi görünürlük ve satışlar için optimize etmek önemlidir. İşte Amazon listelerinizi geliştirmek için bazı SEO ipuçları:",
            image: blog2,
            description: [
                {
                    title: 'Anahtar Kelime Araştırması',
                    content:
                        'Helium 10 veya Jungle Scout gibi araçlar kullanarak kapsamlı anahtar kelime araştırması yapmaya başlayın. Başlık, madde noktaları ve açıklama alanlarında stratejik olarak dahil etmek için ilgili anahtar kelimeleri belirleyin.',
                },
                {
                    title: 'Çekici Başlık',
                    content:
                        'Anahtar kelimelerinizi içeren ve ana özellikleri veya faydaları vurgulayan etkileyici bir başlık oluşturun. Dikkat çekmek ve tıklama oranlarını artırmak için öz ve açıklayıcı tutun.',
                },
                {
                    title: 'Bilgilendirici Madde Noktaları',
                    content:
                        'Ürününüzün temel özelliklerini, faydalarını ve benzersiz satış noktalarını madde noktalarıyla belirtin. Okunabilirliğe odaklanın ve her madde noktasının potansiyel alıcılara değerli bilgi sağladığından emin olun.',
                },
                {
                    title: 'Detaylı Açıklama',
                    content:
                        'Ürününüzün özelliklerini, kullanımlarını ve faydalarını ayrıntılı bir şekilde açıklayan bir ürün açıklaması yazın. İlgili anahtar kelimeleri doğal bir şekilde dahil edin ve özel teklifleri, garanti veya güvenceleri vurgulayın.',
                },
                {
                    title: 'Yüksek Kaliteli Resimler',
                    content:
                        'Ürününüzü birden fazla açıdan ve farklı ortamlarda sergileyen yüksek kaliteli ürün resimlerine yatırım yapın. Ürününüzü doğru ve profesyonel şekilde temsil eden net, profesyonel resimler kullanın ve potansiyel alıcılarla güven oluşturun.',
                },
                {
                    title: 'Geliştirilmiş Marka İçeriği (EBC)',
                    content:
                        'Eğer uygunsa, Geliştirilmiş Marka İçeriğinden faydalanarak ürün listelemeniz için görsel açıdan çekici ve bilgilendirici içerikler oluşturun. EBC yi marka hikayenizi anlatmak, ek ürün resimleri sergilemek ve anahtar özellikleri vurgulamak için kullanın.',
                },
                {
                    title: 'A/B Testi',
                    content:
                        'Başlık, resimler ve madde noktaları gibi liste öğelerinin farklı versiyonlarını sürekli olarak izleyin ve test edin, böylece hedef kitlenizle en iyi şekilde uyum sağlayanı belirleyin. Veri odaklı içgörüler kullanarak listenizi zaman içinde iyileştirin ve optimize edin.',
                },
                {
                    title: 'Müşteri Yorumları',
                    content:
                        'Memnun müşterileri, satın alma sonrası müşteri hizmetleri sağlayarak ve satın alımdan sonra alıcılarla takip ederek olumlu yorumlar bırakmaları için teşvik edin. Olumlu yorumlar, ürününüzün itibarını iyileştirir ve Amazon arama sonuçlarındaki sıralamasını etkiler.',
                },
            ],
            underText: "Bu SEO ipuçlarını uygulayarak, Amazon listelerinizin görünürlüğünü, tıklama oranlarını ve dönüşüm oranlarını artırabilir ve nihayetinde platformdaki işinizi büyütebilirsiniz.",
            type: 'SEO',
            date: "24.12.2023"
        },
        {
            id: 3,
            title: 'Maksimum Sonuçlar İçin Amazon Sponsorlu Ürün Reklamlarınızı Optimize Edin',
            explanation:
                'Amazon reklamcılığında, Sponsorlu Ürün Reklamları, ürün görünürlüğünü artırmak ve satışları artırmak için önemli bir rol oynar. İşte maksimum etki için Sponsorlu Ürün Reklamlarınızı optimize etmenin bazı stratejileri:',
            image: blog3,
            description: [
                {
                    title: 'Hedeflenmiş Anahtar Kelimeler',
                    content:
                        'Ürününüze yakından ilgili ve yüksek arama hacmine sahip ilgili anahtar kelimeleri belirleyin. Bu anahtar kelimeleri reklam kampanyalarınıza dahil ederek reklamlarınızın ilgili arama sonuçlarında görünmesini sağlayın ve potansiyel müşterileri çekin.',
                },
                {
                    title: 'Etkileyici Reklam Metni',
                    content:
                        'Ürününüzün benzersiz özelliklerini ve faydalarını vurgulayan etkileyici reklam metni oluşturun. İkna edici bir dil ve net çağrılarla alıcıları reklamlarınıza tıklamaya ve satın almaya teşvik edin.',
                },
                {
                    title: 'Stratejik Teklif Verme',
                    content:
                        'Reklam hedeflerinize ve bütçenize dayalı olarak teklif stratejinizi belirleyin. Reklamlarınızın performansını izleyin ve tekliflerinizi buna göre ayarlayarak yatırım getirinizi maksimize edin ve istenen reklam hedeflerinize ulaşın.',
                },
                {
                    title: 'Hedeflenmiş Kitle',
                    content:
                        'Ürün hedefleme, kategori hedefleme ve izleyici hedefleme gibi hedefleme seçeneklerinden faydalanarak ideal müşterilerinize ulaşın. Demografi, ilgi alanları ve satın alma davranışlarına özel olarak reklam kampanyalarınızı şekillendirerek ilgili ve etkili hale getirin.',
                },
                {
                    title: 'Reklam Yerleşimi',
                    content:
                        'En üst arama, ürün detay sayfaları ve arama geri kalanı gibi farklı reklam yerleşimlerini deneyerek ürünleriniz için en yüksek dönüşüm oranları ve ROI sağlayan yerleşimleri belirleyin.',
                },
                {
                    title: 'Reklam Zamanlaması',
                    content:
                        'Reklam zamanlamanızı, hedef kitlenizin en aktif olduğu ve satın alma yapma olasılığının en yüksek olduğu zamanlarda reklamlarınızın gösterilmesini sağlamak için optimize edin. Performans verilerine dayalı olarak reklam programlarınızı, yoğun alışveriş dönemlerinde maksimum maruziyet sağlamak üzere ayarlayın.',
                },
                {
                    title: 'Sürekli Optimizasyon',
                    content:
                        'Sponsorlu Ürün Reklamlarınızın performansını düzenli olarak izleyin ve zamanla reklam performansınızı iyileştirmek için veri odaklı optimizasyonlar yapın. Ürünleriniz için en iyi çalışanı belirlemek için farklı reklam yaratıcıları, hedefleme seçenekleri ve teklif stratejileri üzerinde testler yapın.',
                },
                {
                    title: 'Performans İzleme',
                    content:
                        'Tıklama oranı, dönüşüm oranı ve reklam maliyeti gibi temel performans metriklerini takip ederek Sponsorlu Ürün Reklamlarınızın etkinliğini ölçün. Bu verileri kullanarak reklam kampanyalarınızı rafine edin ve daha iyi sonuçlar için reklam stratejinizi optimize edin.',
                },
            ],
            underText:
                'Bu stratejileri uygulayarak, Amazon Sponsorlu Ürün Reklamlarınızı platformdaki ürünleriniz için daha fazla trafik, dönüşüm ve satış elde etmek için optimize edebilirsiniz.',
            type: 'Amazon',
            date: '07.01.2024',
        },
        {
            id: 4,
            title: 'Google Ads: Dijital Pazarlamada Başarıya Giden Yolun Temelleri',
            explanation: "Google Ads, dijital pazarlama dünyasında işletmelerin etkili bir şekilde hedef kitlelerine ulaşmalarını sağlayan önemli bir araçtır. Bu platformun doğru şekilde kullanılması, işletmelerin çevrimiçi varlıklarını geliştirmesi ve potansiyel müşterileri etkilemesi açısından kritiktir. İşte Google Ads'i başlangıç seviyesinde anlamak ve kullanmak için temel bilgiler:",
            image: blog4,
            description: [
                {
                    title: '1. Google Ads Nedir ve Önemi',
                    content: "Google Ads, Google'ın reklam ağıdır ve işletmelerin Google arama sonuçları, YouTube, Gmail ve diğer Google platformlarında reklam yayınlamasını sağlar. Bu platform, doğru stratejilerle kullanıldığında hedef kitleye erişim, marka bilinirliği ve satışları artırma gibi önemli faydalar sağlar.",
                },
                {
                    title: '2. Google Ads Nasıl Çalışır?',
                    content: "Google Ads'i kullanmak, belirlenen anahtar kelimelerle ilişkili arama yapan kullanıcılara hedeflenmiş reklamlar göstererek başlar. Bu reklamlar, kullanıcılara ürünlerinizi veya hizmetlerinizi tanıtmak, web sitenize trafik çekmek ve dönüşümleri artırmak için tasarlanır. Google Ads'in etkili kullanımı, doğru anahtar kelimelerin seçimi, ilgi çekici reklam metinleri oluşturma ve bütçe yönetimi gibi faktörlere dayanır.",
                },
                {
                    title: '3. Google Ads Kullanmanın Avantajları',
                    content: "Google Ads'i başarılı bir şekilde kullanmanın avantajları şunlardır:",
                    advantages: [
                        "Hedeflenmiş Reklamlar: Belirlenen hedef kitlenize doğrudan ulaşım sağlar.",
                        "Esnek Bütçe Kontrolü: Günlük bütçe ayarları ve teklif stratejileri ile reklam harcamalarınızı yönetebilirsiniz.",
                        "Ölçülebilir Sonuçlar: Reklam performansınızı detaylı raporlar ve analizlerle izleyerek optimize edebilirsiniz.",
                        "Hızlı Sonuçlar: Diğer pazarlama yöntemlerine göre daha hızlı sonuçlar elde edebilirsiniz."
                    ]
                },
                {
                    title: "4. Google Ads'te Başarı İçin İpuçları",
                    content: "Google Ads'te başarılı kampanyalar oluşturmak için şu ipuçlarına dikkat edin:",
                    advantages: [
                        "Anahtar Kelime Araştırması: Potansiyel müşterilerinizin kullandığı anahtar kelimeleri belirleyin.",
                        "Reklam Metni ve Görüntüleri: Çekici ve dikkat çekici reklam metinleri ve görselleri oluşturun.",
                        "A/B Testleri: Farklı reklam formatlarını ve stratejilerini test ederek en etkili olanları belirleyin.",
                        "Sürekli İyileştirme: Kampanyalarınızı sürekli olarak izleyin, analiz edin ve optimize edin."
                    ]

                },
            ],
            underText: "Google Ads, dijital pazarlama stratejilerinin önemli bir parçasıdır ve başlangıç seviyesinde bile etkili sonuçlar elde edebilmeniz için gereken araçları sunar. Doğru stratejilerle kullanıldığında, Google Ads işletmenizin çevrimiçi varlığını güçlendirebilir ve başarıya giden yolda önemli bir rol oynar.",
            type: 'Google Ads',
            date: "26.04.2024"
        },
        {
            id: 5,
            title: 'Google Ads: İleri Seviye Stratejiler ve Uygulamalar',
            explanation: "Dijital pazarlama dünyasında, Google Ads'in etkili bir şekilde kullanılması şirketlerin büyümesi ve çevrimiçi varlıklarını güçlendirmesi için önemli bir faktördür. Bu yazıda, Google Ads'i ileri seviyede kullanmak isteyenler için stratejik yaklaşımlar ve uygulamalar ele alınacaktır.",
            image: blog5,
            description: [
                {
                    title: '1. Hedef Kitlenin Belirlenmesi ve Segmentasyon',
                    content: "Google Ads kampanyalarınızı oluştururken, hedef kitlenizi detaylı bir şekilde tanımlamak ve segmentasyon yapmak önemlidir. Demografik veriler, davranışsal özellikler, ilgi alanları ve kullanıcı davranışları gibi verilerden yararlanarak daha doğru hedefleme stratejileri oluşturabilirsiniz. Ayrıca, önceki etkileşimleri göz önünde bulundurarak remarketing ve benzer hedefleme gibi ileri seviye segmentasyon teknikleri de kullanılabilir.",
                },
                {
                    title: '2. Anahtar Kelime Stratejisi ve Uzun Kuyruk Anahtar Kelimeler',
                    content: "İleri seviye anahtar kelime stratejisi, dönüşüm odaklı anahtar kelimelerin yanı sıra uzun kuyruk anahtar kelimelerini de içerir. Uzun kuyruk anahtar kelimeleri, daha spesifik ve niş kelimelerdir ve daha düşük rekabete sahiptirler. Bu kelimelerle hedef kitlenizi daha hassas bir şekilde hedefleyebilir ve dönüşüm oranlarınızı artırabilirsiniz.",
                },
                {
                    title: '3. Reklam Uzantılarının Etkin Kullanımı',
                    content: "İleri seviye Google Ads stratejilerinden biri de reklam uzantılarının etkin kullanımıdır. Site bağlantıları, çağrı uzantıları, konum uzantıları ve fiyat uzantıları gibi çeşitli reklam uzantıları kullanarak reklamlarınızı daha zengin ve bilgilendirici hale getirebilirsiniz. Bu uzantılar, kullanıcıların dikkatini çekerek tıklama oranlarını artırabilir ve dönüşümleri teşvik edebilir.",
                },
                {
                    title: '4. Teklif Stratejileri ve Otomatik Optimizasyon Araçları',
                    content: "Google Ads platformu, farklı teklif stratejileri sunar ve ileri seviye kullanıcılar bu stratejileri kullanarak kampanyalarını daha verimli hale getirebilirler. Dönüşümlere odaklanan teklif stratejileri, reklamlarınızın daha fazla dönüşüm elde etmesine yardımcı olabilir. Ayrıca, otomatik teklif stratejileri ve otomatik optimizasyon araçları kullanarak kampanyalarınızı sürekli olarak optimize edebilirsiniz."

                },
                {
                    title: '5. A/B Testleri ve Performans Analizi',
                    content: "İleri seviye Google Ads stratejileri arasında A/B testleri ve performans analizleri de yer alır. Farklı reklam formatları, başlık metinleri, çağrılar ve teklifler üzerinde testler yaparak en etkili olanları belirleyebilirsiniz. Ayrıca, detaylı performans analizleri yaparak kampanyalarınızı sürekli olarak iyileştirebilir ve optimize edebilirsiniz."
                }
            ],
            underText: "Google Ads, doğru stratejiler ve uygulamalarla kullanıldığında şirketlerin çevrimiçi varlıklarını güçlendirmelerine ve başarıya ulaşmalarına yardımcı olur. Yukarıda belirtilen ileri seviye stratejileri kullanarak Google Ads kampanyalarınızı daha etkili hale getirebilir ve hedeflerinize ulaşabilirsiniz.",
            type: 'Google Ads',
            date: "28.04.2024"
        },
    ];

    const currentBlog = blogData.find((blog) => blog.id === parseInt(id));
    return (
        <MainLayout>
            <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16 relative">
                <div className="mb-5 mx-auto">
                    <Typography color="white" variant="h3"
                                className="mb-2">
                        {currentBlog?.title}
                    </Typography>
                    <Typography color="white" variant="p" className="">
                        {currentBlog?.explanation}
                    </Typography>
                    <div className="text-gray-700 text-xs flex my-6 justify-between">
                        <div className="flex items-center">
                            <Link to="">
                                <Avatar
                                    src={raLogo}
                                    alt="ra digital"
                                    withBorder={true}
                                    className="w-12 h-12 mr-3 object-contain"
                                />
                            </Link>
                            <div className="text-sm">
                                <div
                                    className="text-gray-200 font-medium leading-none hover:text-indigo-600 transition duration-500 ease-in-out cursor-pointer">
                                    Ra Digital
                                </div>
                                <p className="text-gray-600 text-xs">
                                    {currentBlog?.date}
                                </p>
                            </div>
                        </div>
                        <div className="flex-row flex items-center justify-center gap-3">
                            <Link to="/">
                                <FaTwitter
                                    className="w-5 h-5 hover:text-[#1DA1F2] duration-300 transition hover:duration-300"/>
                            </Link>
                            <Link to="https://www.linkedin.com/company/radigital-io/about/?viewAsMember=true">
                                <FaLinkedin
                                    className="w-5 h-5 hover:text-[#0a66c2] duration-300 transition hover:duration-300"/>
                            </Link>
                            <Link to="/">
                                <FaTiktok
                                    className="w-5 h-5 hover:text-[#f7004d] duration-300 transition hover:duration-300"/>
                            </Link>
                            <Link to="https://www.instagram.com/radigitaltr">
                                <FaInstagram
                                    className="w-5 h-5 hover:text-[#E4405F] duration-300 hover:transition hover:hover:duration-300"/>
                            </Link>
                        </div>
                    </div>
                </div>

                {/*<div className="bg-cover h-64 text-center overflow-hidden object-contain"*/}
                {/*     style={{*/}
                {/*         height: '450px',*/}
                {/*         backgroundImage: `url(${currentBlog.image})`,*/}
                {/*     }}*/}
                {/*></div>*/}
                <img className="w-full sm:h-[500px] sm:object-contain object-cover rounded-md bg-[#5A648D26]"
                     src={currentBlog?.image} alt={currentBlog?.image}/>
                <div className="mx-auto">
                    <div
                        className="my-3 bg-[#5A648D26] text-white rounded-b lg:rounded-b-none lg:rounded-r flex flex-col justify-between leading-normal">
                        <div className="px-3">
                            {currentBlog.description.map((step, index) => (
                                <div key={index} className="mb-6">
                                    <h2 className="text-xl font-bold my-2">{step?.title}</h2>

                                    <p className="text-base leading-8 my-2">{step?.content}</p>

                                    <ul className="list-disc pl-3">
                                        {step?.advantages?.map((advantage, index) => (
                                            <li key={index} className="text-base leading-8 my-2">{advantage}</li>
                                        ))}
                                    </ul>

                                </div>
                            ))}
                        </div>

                        <p className="text-base leading-8 my-5 px-3">{currentBlog?.underText}</p>
                        <div className="flex flex-wrap gap-2 px-3 pb-3">
                            <Link to="/"
                                  className="text-xs text-indigo-600 font-medium hover:text-gray-400 transition duration-500 ease-in-out">
                                #SEO
                            </Link>
                            <Link to="/"
                                  className="text-xs text-indigo-600 font-medium hover:text-gray-400 transition duration-500 ease-in-out">
                                #Amazon
                            </Link>
                            <Link to="/"
                                  className="text-xs text-indigo-600 font-medium hover:text-gray-400 transition duration-500 ease-in-out">
                                #RaDigital
                            </Link>
                            <Link to="/"
                                  className="text-xs text-indigo-600 font-medium hover:text-gray-400 transition duration-500 ease-in-out">
                                #İpuçları
                            </Link>
                            <Link to="/"
                                  className="text-xs text-indigo-600 font-medium hover:text-gray-400 transition duration-500 ease-in-out">
                                #Pazarlama
                            </Link>
                        </div>

                    </div>
                </div>
            </div>
        </MainLayout>
    );
}
