import React from 'react'
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import {motion} from "framer-motion";
import {
    Timeline, TimelineBody, TimelineConnector, TimelineHeader, TimelineIcon, TimelineItem, Typography
} from "@material-tailwind/react";
import improve from "../../../Assets/Images/story/improve.png";
import bannersShopify from "../../../Assets/Images/banners/Shopıfy Market Management.webp";

export default function TwitterAds() {
    const bannerHeader = "Twitter Ads: Stratejik Dijital Pazarlama Çözümleri";
    const bannerTxt = "Twitter Ads ile hedef kitlenize ulaşın, marka bilinirliğinizi artırın ve satışlarınızı artırın.";
    return (

        <div>
            <ServiceDetailBanner bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={bannersShopify}/>
            <section className="pt-6">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className=" mx-auto flex flex-col justify-center items-center ">
                    <div className="flex flex-col justify-center items-center gap-3 px-6 max-w-6xl">
                        <Typography color="white" variant="h3" className="nbXs:text-center">
                            Twitter Ads Stratejileri
                        </Typography>
                        <Typography color="white" variant="paragraph">
                            Twitter Ads, dijital pazarlama stratejilerinizde önemli bir rol oynayabilir ve işletmenizin
                            çevrimiçi varlığını güçlendirmenize yardımcı olabilir. İşte Twitter Ads'in sunduğu
                            fırsatları en iyi şekilde değerlendirebilmek için izlemeniz gereken stratejiler:
                        </Typography>
                    </div>
                </motion.div>
            </section>
            <section className="py-6 flex justify-center ">
                <div className="xl:w-[70%] lg:w-[65%] md:w-[65%] p-8 pt-2 shadow-basicShadow">
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    1
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Hedef Kitle Analizi ve Segmentasyon
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            Twitter Ads kampanyalarınızın etkili olabilmesi için öncelikle hedef
                                            kitlenizi doğru bir şekilde tanımlamanız gerekmektedir. Demografik veriler,
                                            ilgi alanları, sektörel tercihler gibi faktörleri analiz ederek segmentlere
                                            ayırabilir ve hedef kitlenizi daha etkili bir şekilde hedefleyebilirsiniz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    2
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    İçerik Stratejisi ve Kaliteli İçerik Üretimi
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            Twitter Ads'te paylaşacağınız içeriklerin kaliteli olması ve hedef
                                            kitlenizin ilgisini çekecek şekilde tasarlanması önemlidir. Görsel
                                            içerikler, etkileyici başlıklar ve net mesajlar içeren metinler ile
                                            kullanıcıların dikkatini çekebilir ve marka bilinirliğinizi
                                            artırabilirsiniz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    3
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Etkin Reklam Kampanyaları ve Analiz
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            Twitter Ads platformunda etkin reklam kampanyaları oluşturarak markanızın
                                            görünürlüğünü artırabilirsiniz. A/B testleri yaparak farklı reklam
                                            formatlarını ve metinlerini test edebilir, performanslarını analiz ederek
                                            kampanyalarınızı sürekli olarak optimize edebilirsiniz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    4
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Hashtag ve Trendlere Entegrasyon
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            Twitter, hashtag ve trendlerin güçlü olduğu bir platformdur. Doğru
                                            hashtag'leri kullanarak ve güncel trendlere entegre olarak kullanıcıların
                                            ilgisini çekebilir ve organik erişimi artırabilirsiniz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    5
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Veri Analizi ve Raporlama
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            Twitter Ads kampanyalarınızın performansını düzenli olarak analiz ederek ve
                                            detaylı raporlar oluşturarak stratejilerinizi güçlendirebilirsiniz. Veri
                                            analizi sayesinde kampanyalarınızın başarısını ölçebilir, dönüşümleri
                                            artırabilir ve bütçenizi daha verimli kullanabilirsiniz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                        Neden Bizi Seçmelisiniz?
                    </Typography>
                    <div className="lg:px-6 lg:w-[55%] w-full">
                        <div className="flex flex-col justify-center items-center gap-3 w-full">
                            <Typography color="white" variant="h3"
                                        className="lg:block hidden text-center nbXs:text-center pb-3">
                                Neden Bizi Seçmelisiniz?
                            </Typography>
                            <Typography color="white" variant="paragraph">
                                <ul>
                                    <li className="text-justify">
                                        Twitter Ads kampanyalarınızın performansını düzenli olarak analiz ederek ve
                                        detaylı raporlar oluşturarak stratejilerinizi güçlendirebilirsiniz. Veri analizi
                                        sayesinde kampanyalarınızın başarısını ölçebilir, dönüşümleri artırabilir ve
                                        bütçenizi daha verimli kullanabilirsiniz.
                                    </li>
                                </ul>
                            </Typography>
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-full max-h-[325x] nbXs:max-h-max">
                        <img src={improve} alt="twitter ads"
                             className="w-full max-h-[325px] nbXs:max-h-max object-cover drop-shadow-xl xl:object-cover object-contain"/>
                    </div>
                </motion.div>
            </section>


        </div>


    )
}