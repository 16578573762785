import React from 'react'
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import {motion} from "framer-motion";
import {
    Timeline, TimelineBody, TimelineConnector, TimelineHeader, TimelineIcon, TimelineItem, Typography
} from "@material-tailwind/react";
import improve from "../../../Assets/Images/story/improve.png";
import bannersShopify from "../../../Assets/Images/banners/Shopıfy Market Management.webp";

export default function YoutubeAds() {
    const bannerHeader = "YouTube Ads: Video Pazarlama Stratejileri ve Etkili Kampanya Yönetimi";
    const bannerTxt = "YouTube Ads ile hedef kitlenize ulaşın, marka bilinirliğinizi artırın ve dönüşümleri artırın."
    return (

        <div>
            <ServiceDetailBanner bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={bannersShopify}/>
            <section className="pt-6">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className=" mx-auto flex flex-col justify-center items-center ">
                    <div className="flex flex-col justify-center items-center gap-3 px-6 max-w-6xl">
                        <Typography color="white" variant="h3" className="nbXs:text-center">
                            YouTube Ads Stratejileri
                        </Typography>
                        <Typography color="white" variant="paragraph">
                            YouTube, milyonlarca kullanıcının video içeriklerini izlediği ve paylaştığı popüler bir
                            platformdur. YouTube Ads kullanarak, işletmeler hedef kitlelerine daha etkili bir şekilde
                            ulaşabilir, marka bilinirliğini artırabilir ve dönüşümleri teşvik edebilir. İşte YouTube
                            Ads'in sunduğu fırsatları en iyi şekilde değerlendirebilmek için izlemeniz gereken
                            stratejiler:
                        </Typography>
                    </div>
                </motion.div>
            </section>
            <section className="py-6 flex justify-center ">
                <div className="xl:w-[70%] lg:w-[65%] md:w-[65%] p-8 pt-2 shadow-basicShadow">
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    1
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Hedef Kitlenin Belirlenmesi ve Video İçerik Üretimi
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            YouTube Ads kampanyalarınızın etkili olabilmesi için öncelikle hedef
                                            kitlenizi doğru bir şekilde tanımlamanız ve segmentasyon yapmanız
                                            gerekmektedir. Demografik veriler, ilgi alanları, arama terimleri gibi
                                            faktörleri analiz ederek hedef kitlenizi daha spesifik bir şekilde
                                            hedefleyebilirsiniz. Ayrıca, etkileyici ve ilgi çekici video içerikleri
                                            üreterek kullanıcıların dikkatini çekebilir ve etkileşimi artırabilirsiniz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    2
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Reklam Formatları ve Etkin Kampanya Yönetimi
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            YouTube Ads platformunda çeşitli reklam formatları bulunmaktadır. Bunlar
                                            arasında TrueView reklamları, bumper reklamları, overlay reklamları ve
                                            sponsorlu kartlar gibi seçenekler yer almaktadır. İhtiyaçlarınıza ve
                                            hedeflerinize uygun reklam formatlarını seçerek etkin kampanyalar
                                            oluşturabilir ve reklam bütçenizi en verimli şekilde kullanabilirsiniz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    3
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Dönüşüm Hedefleri ve Performans Analizi
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            YouTube Ads kampanyalarınızın performansını düzenli olarak analiz ederek,
                                            dönüşüm hedeflerinizi belirleyebilir ve kampanyalarınızı buna göre optimize
                                            edebilirsiniz. Dönüşüm oranları, tıklama sayıları, izlenme süreleri gibi
                                            metrikleri izleyerek kampanyalarınızın başarısını ölçebilir ve
                                            stratejilerinizi güçlendirebilirsiniz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    4
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Etkileşim ve Topluluk Yönetimi
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            YouTube kullanıcıları genellikle etkileşime açık ve katılımcıdırlar. Bu
                                            nedenle, YouTube Ads kampanyalarınızda kullanıcılarla etkileşim kuracak ve
                                            topluluğunuzu yönetecek içerikler paylaşmalısınız. Yorumlara yanıt verme,
                                            video içerikleri paylaşma ve toplulukla etkileşimde bulunma gibi
                                            stratejilerle kullanıcıların ilgisini çekebilir ve sadık bir izleyici
                                            kitlesi oluşturabilirsiniz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    5
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Veri Analizi ve Optimizasyon
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            YouTube Ads kampanyalarınızın performansını düzenli olarak analiz ederek,
                                            veri odaklı kararlar alabilir ve kampanyalarınızı sürekli olarak optimize
                                            edebilirsiniz. A/B testleri yaparak farklı reklam formatlarını, başlık
                                            metinlerini ve görselleri test edebilir, performanslarını analiz ederek
                                            kampanyalarınızı geliştirebilirsiniz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                        Neden Bizi Seçmelisiniz?
                    </Typography>
                    <div className="lg:px-6 lg:w-[55%] w-full">
                        <div className="flex flex-col justify-center items-center gap-3 w-full">
                            <Typography color="white" variant="h3"
                                        className="lg:block hidden text-center nbXs:text-center pb-3">
                                Neden Bizi Seçmelisiniz?
                            </Typography>
                            <Typography color="white" variant="paragraph">
                                <ul>
                                    <li className="text-justify">
                                        YouTube Ads, işletmeler için güçlü bir video pazarlama aracı olarak öne
                                        çıkmaktadır. Yukarıda belirtilen stratejileri kullanarak YouTube Ads
                                        kampanyalarınızı başarıyla yönetebilir, marka bilinirliğinizi artırabilir ve
                                        hedef kitlenize daha etkili bir şekilde ulaşabilirsiniz.
                                    </li>
                                </ul>
                            </Typography>
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-full max-h-[325x] nbXs:max-h-max">
                        <img src={improve} alt="Youtube Ads"
                             className="w-full max-h-[325px] nbXs:max-h-max object-cover drop-shadow-xl xl:object-cover object-contain"/>
                    </div>
                </motion.div>
            </section>


        </div>


    )
}