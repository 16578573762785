import React from 'react';
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import aPlusContentBottom from "../../../Assets/Images/story/aPlusContentBottom.svg";
import {
    Typography, Timeline, TimelineItem, TimelineConnector, TimelineHeader, TimelineIcon, TimelineBody
} from "@material-tailwind/react";
import {motion} from "framer-motion";
import bannersAplus from "../../../Assets/Images/banners/A+.webp";

export default function APlusContentCreation() {
    const bannerHeader = "A+ İçerik Oluşturma: Görsel Çekicilikten Daha Fazlası";
    const bannerTxt = "A+ içerik hizmetimiz, yenilikçi tasarımı stratejik pazarlama ile birleştirerek izleyicinizi etkilemeyi hedefler. Marka hikayenizi maksimum etki ve dönüşümler için yükseltin."
    return (
        <div>
            <ServiceDetailBanner bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={bannersAplus}/>
            <motion.section
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.6}}
                className="bg-gradient-to-r from-[#64748b] via-[#414e63] to-[#202c3e] py-8 rounded-md shadow-2xl mb-6">
                <div className="flex flex-col justify-center items-center gap-3 lg:px-6 px-2 max-w-6xl mx-auto">
                    <Typography color="white" variant="h3" className="text-center nbXs:text-center">
                        Bizim A+ İçerik Oluşturma Yaklaşımımız
                    </Typography>
                    <Typography color="white" variant="h4"
                                className="text-center nbXs:text-center pb-3 lg:text-xl text-lg nbXs:text-base">
                        Endüstri veteranlarımız, estetik çekiciliği stratejik pazarlama içgörüleriyle birleştirerek A+
                        İçerik Oluşturma alanında zengin bir deneyim sunuyor. Özel grafik tasarım, detaylı
                        karşılaştırmalı tablolar ve çoklu ortam öğeleri aracılığıyla geliştirilmiş marka hikayeleri
                        sunuyoruz. Bu sayede içeriğiniz Amazon'da öne çıkıyor ve müşteri katılımını ve sadakatini
                        artırıyor.
                    </Typography>
                </div>
            </motion.section>

            <section className="py-6 flex justify-center ">
                <div className="xl:w-[55%] lg:w-[50%] md:w-[65%] p-8 shadow-basicShadow">
                    <Typography color="white" variant="h4" className="nbXs:text-center pb-3">
                        A+ İçerik Oluşturmanın Temel Unsurları
                    </Typography>
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    1
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Özelleştirilmiş Grafik Tasarım
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Marka kimliğinizle mükemmel uyum sağlayan özelleştirilmiş grafik tasarımıyla Amazon
                                    ürün sayfalarınızı yükseltin.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    2
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Karşılaştırmalı Tablolar
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Detaylı karşılaştırmalı tablolar ile ürününüzün rakiplere karşı benzersiz satış
                                    noktalarını vurgulayın.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    3
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Geliştirilmiş Marka Hikayesi Anlatımı
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Görsellerin ötesine geçerek çoklu ortam öğeleriyle etkileyici bir marka hikayesi
                                    oluşturun, müşteri katılımını ve sadakatini artırın.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                        Ra Digital'ı A+ İçerik Oluşturma İçin Neden Seçmelisiniz?
                    </Typography>
                    <div className="lg:px-6 lg:w-[55%] w-full">
                        <div className="flex flex-col justify-center items-center gap-3 w-full">
                            <Typography color="white" variant="h3"
                                        className="lg:block hidden text-center nbXs:text-center pb-3">
                                Ra Digital'ı A+ İçerik Oluşturma İçin Neden Seçmelisiniz?
                            </Typography>
                            <Typography color="white" variant="paragraph">
                                <ul className="list-disc">
                                    <li>
                                        Endüstri Veterani: Ekibimiz, A+ İçerik Oluşturma konusunda geniş deneyime
                                        sahiptir ve içeriğinizin görsel çekiciliği ile stratejik pazarlama içgörülerini
                                        birleştirerek kaliteli sonuçlar elde eder.
                                    </li>
                                    <li>
                                        Özelleştirilmiş Çözümler: Markanızın hedefleriyle uyumlu benzersiz çözümler
                                        sunan özel grafik tasarım, karşılaştırmalı tablolar ve marka hikayesi anlatımı
                                        gibi hizmetlerimizle öne çıkıyoruz.
                                    </li>
                                    <li>
                                        Müşteri Katılımı: Görsellerin ötesine geçerek, etkileyici hikayeler ve çoklu
                                        ortam öğeleri aracılığıyla müşteri katılımını ve sadakatini artırmaya
                                        odaklanıyoruz.
                                    </li>
                                </ul>
                            </Typography>
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-2/3 lg:max-h-[325x]">
                        <img src={aPlusContentBottom} alt="A+ Content Yönetimi"
                             className="w-full max-h-full nbXs:max-h-[275px] drop-shadow-xl xl:object-cover object-contain"/>
                    </div>
                </motion.div>
            </section>
        </div>
    );
}
