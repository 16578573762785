import React from 'react';
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import seoBrowser from "../../../Assets/Images/story/seoBrowser.svg";
import bannersSeo from "../../../Assets/Images/banners/SEO.webp";
import {
    Typography, Timeline, TimelineItem, TimelineConnector, TimelineHeader, TimelineIcon, TimelineBody
} from "@material-tailwind/react";
import {motion} from "framer-motion";

export default function SEOOptimization() {
    const bannerHeader = "SEO Optimizasyon Hizmetimizle Görünürlüğü Mükemmelleştirin"
    const bannerTxt = "Markanızın dijital ortamda öne çıkmasını sağlayarak çevrimiçi varlığınızı artırın. SEO optimizasyon hizmetimiz sayesinde markanızın dijital peyzajda ön planda olmasını sağlıyoruz."
    return (
        <div>
            <ServiceDetailBanner bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={bannersSeo}/>
            <motion.section
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.6}}
                className="bg-gradient-to-r from-[#64748b] via-[#414e63] to-[#202c3e] py-8 rounded-md shadow-2xl mb-6">
                <div className="flex flex-col justify-center items-center gap-3 lg:px-6 px-2 max-w-6xl mx-auto">
                    <Typography color="white" variant="h3" className="text-center nbXs:text-center">
                        SEO Optimizasyona Yaklaşımımız
                    </Typography>
                    <Typography color="white" variant="h4"
                                className="text-center nbXs:text-center pb-3 lg:text-xl text-lg nbXs:text-base">
                        Rekabetçi çevrimiçi perakende ortamında, görünürlük çok önemlidir. SEO Optimizasyon hizmetimiz,
                        ürünlerinizin müşteri aramalarında ön plana çıkmasını sağlar. Gelişmiş algoritmaları kullanarak
                        ve Amazon'un sürekli değişen arama motoru mekaniğinden önde olmaya çalışarak, şunları sunuyoruz:
                    </Typography>
                </div>
            </motion.section>

            <section className="py-6 flex justify-center ">
                <div className="xl:w-[55%] lg:w-[50%] md:w-[65%] p-8 shadow-basicShadow">
                    <Typography color="white" variant="h4" className="nbXs:text-center pb-3">
                        SEO Optimizasyon Hizmetimizle Neler Sunuyoruz?
                    </Typography>
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    1
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Detaylı Anahtar Kelime Araştırması ve Optimizasyonu
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            Kapsamlı anahtar kelime araştırması ve optimizasyonu ile hem kısa kuyruklu
                                            hem de uzun kuyruklu ifadeleri hedefleyin.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    2
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Ürün Başlıkları, Açıklamaları ve Arka Planda Arama Terimlerinin Optimizasyonu
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            Ürün başlıklarını, açıklamalarını ve arka plandaki arama terimlerini
                                            optimize ederek
                                            ürünlerinizi müşteri aramalarında ön plana çıkarırız.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    3
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Düzenli Performans Analizi ve Ayarlamaları
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            Algoritma değişikliklerinden ve pazar trendlerinden önce, düzenli performans
                                            analizi ve ayarlamaları ile adımınızı önde tutun.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                        Neden SEO Optimizasyonu için Ra Digital'i Seçmelisiniz?
                    </Typography>
                    <div className="lg:px-6 lg:w-[55%] w-full">
                        <div className="flex flex-col justify-center items-center gap-3 w-full">
                            <Typography color="white" variant="h3"
                                        className="lg:block hidden text-center nbXs:text-center pb-3">
                                Neden SEO Optimizasyonu için Ra Digital'i Seçmelisiniz?
                            </Typography>
                            <Typography color="white" variant="paragraph">
                                <ul className="list-disc">
                                    <li>
                                        Gelişmiş Algoritmalar: Ürünlerinizin müşteri aramalarında maksimum görünürlüğünü
                                        sağlamak için gelişmiş algoritmaları kullanıyoruz.
                                    </li>
                                    <li>
                                        Kapsamlı Optimizasyon: Anahtar kelime araştırmasından ürün başlıklarına ve
                                        açıklamalarına kadar her yönüyle kapsamlı bir şekilde optimizasyon sağlıyoruz,
                                        Amazon'da artırılmış görünürlük
                                    </li>
                                    <li>
                                        Uyum Sağlama: Düzenli performans analizi ve ayarlamalarımız, sizi algoritma
                                        değişikliklerinin ve pazar trendlerinin önünde tutar.
                                    </li>
                                </ul>
                            </Typography>
                        </div>
                    </div>
                    <div className="flex justify-center lg:w-[45%] w-full max-h-[325x] nbXs:max-h-1/2">
                        <img src={seoBrowser} alt="radigital seo"
                             className="lg:w-full sm:w-2/3 w-full  max-h-full object-cover drop-shadow-xl xl:object-cover object-contain"/>
                    </div>
                </motion.div>
            </section>
        </div>
    );
}
