import React from 'react'
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import {motion} from "framer-motion";
import {
    Timeline, TimelineBody, TimelineConnector, TimelineHeader, TimelineIcon, TimelineItem, Typography
} from "@material-tailwind/react";
import improve from "../../../Assets/Images/story/improve.png";
import bannersShopify from "../../../Assets/Images/banners/Shopıfy Market Management.webp";

export default function TiktokAds() {
    const bannerHeader = "TikTok Ads: Yaratıcı Pazarlama Stratejileri ve Etkin Reklam Kampanyaları";
    const bannerTxt = "TikTok Ads platformunu kullanarak markanızı geniş kitlelere ulaştırın ve etkili reklam kampanyaları oluşturun.";
    return (

        <div>
            <ServiceDetailBanner bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={bannersShopify}/>
            <section className="pt-6">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className=" mx-auto flex flex-col justify-center items-center ">
                    <div className="flex flex-col justify-center items-center gap-3 px-6 max-w-6xl">
                        <Typography color="white" variant="h3" className="nbXs:text-center">
                            TikTok Ads Stratejileri
                        </Typography>
                        <Typography color="white" variant="paragraph">
                            TikTok, genç ve dinamik bir kullanıcı kitlesine sahip olan popüler bir sosyal medya
                            platformudur. TikTok Ads kullanarak, işletmeler hedef kitlenize doğrudan ulaşabilir, marka
                            bilinirliğinizi artırabilir ve etkileyici reklam kampanyaları oluşturabilirsiniz. İşte
                            TikTok Ads'in sunduğu fırsatları en iyi şekilde değerlendirebilmek için izlemeniz gereken
                            stratejiler:
                        </Typography>
                    </div>
                </motion.div>
            </section>
            <section className="py-6 flex justify-center ">
                <div className="xl:w-[70%] lg:w-[65%] md:w-[65%] p-8 pt-2 shadow-basicShadow">
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    1
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Hedef Kitlenin Tanımlanması ve Segmentasyon
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            TikTok Ads kampanyalarınızın etkili olabilmesi için öncelikle hedef
                                            kitlenizi doğru bir şekilde tanımlamanız ve segmentasyon yapmanız
                                            gerekmektedir. TikTok'un genç kullanıcı kitlesi üzerinde etkili olmak
                                            istiyorsanız, demografik veriler, ilgi alanları, kullanıcı davranışları gibi
                                            faktörleri analiz ederek hedef kitlenizi belirleyebilir ve reklamlarınızı
                                            buna göre optimize edebilirsiniz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    2
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Yaratıcı İçerik Üretimi ve Trendlere Uyum
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            TikTok kullanıcıları yenilikçi ve yaratıcı içeriklere ilgi gösterirler. Bu
                                            nedenle, TikTok Ads kampanyalarınızda dikkat çekici ve eğlenceli içerikler
                                            üretmelisiniz. Trendlere uyum sağlayarak ve platformun özgünlüğünü koruyarak
                                            kullanıcıların ilgisini çekebilir ve etkileşimi artırabilirsiniz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    3
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Reklam Formatları ve Kampanya Stratejileri
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            TikTok Ads platformunda çeşitli reklam formatları bulunmaktadır. Bunlar
                                            arasında In-Feed Ads, Branded Hashtag Challenge, TopView Ads gibi seçenekler
                                            yer almaktadır. İşletmenizin hedeflerine ve bütçenize uygun reklam
                                            formatlarını seçerek etkin kampanyalar oluşturabilir ve dönüşümleri
                                            artırabilirsiniz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    4
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Etkin Analiz ve Optimizasyon
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            TikTok Ads kampanyalarınızın performansını düzenli olarak analiz ederek,
                                            veri odaklı kararlar alabilir ve kampanyalarınızı sürekli olarak optimize
                                            edebilirsiniz. İzlenme sayıları, etkileşim oranları, dönüşüm değerleri gibi
                                            metrikleri izleyerek kampanyalarınızın başarısını ölçebilir ve
                                            stratejilerinizi güçlendirebilirsiniz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    5
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Influencer İşbirlikleri ve Topluluk Yönetimi
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            TikTok platformunda influencer işbirlikleri ve topluluk yönetimi önemli bir
                                            role sahiptir. İlgili influencer'lar ile işbirlikleri yaparak, geniş
                                            kitlelere ulaşabilir ve marka bilinirliğinizi artırabilirsiniz. Ayrıca,
                                            topluluk yönetimi stratejileri ile kullanıcılarla etkileşimde bulunarak
                                            sadık bir izleyici kitlesi oluşturabilirsiniz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                        Neden Bizi Seçmelisiniz?
                    </Typography>
                    <div className="lg:px-6 lg:w-[55%] w-full">
                        <div className="flex flex-col justify-center items-center gap-3 w-full">
                            <Typography color="white" variant="h3"
                                        className="lg:block hidden text-center nbXs:text-center pb-3">
                                Neden Bizi Seçmelisiniz?
                            </Typography>
                            <Typography color="white" variant="paragraph">
                                <ul>
                                    <li className="text-justify">
                                        TikTok Ads, dinamik ve yenilikçi pazarlama stratejileri için güçlü bir
                                        platformdur. Yukarıda belirtilen stratejileri kullanarak TikTok Ads
                                        kampanyalarınızı başarıyla yönetebilir, marka bilinirliğinizi artırabilir ve
                                        genç hedef kitlenize ulaşabilirsiniz.
                                    </li>
                                </ul>
                            </Typography>
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-full max-h-[325x] nbXs:max-h-max">
                        <img src={improve} alt="tiktok advertising improvement"
                             className="w-full max-h-[325px] nbXs:max-h-max object-cover drop-shadow-xl xl:object-cover object-contain"/>
                    </div>
                </motion.div>
            </section>


        </div>


    )
}