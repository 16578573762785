import React from 'react';
import {Link} from "react-router-dom";
import {BsInstagram, BsLinkedin} from "react-icons/bs";
import {Typography} from "@material-tailwind/react";
import {Form} from "../ContactForm/Form";
import {RiCustomerService2Line,RiMailSendLine } from "react-icons/ri";
import {AiOutlineQuestionCircle} from "react-icons/ai";
import {LuClipboardSignature} from "react-icons/lu";
import {IoMailOpenOutline} from "react-icons/io5";
import { MdOutlinePhoneInTalk } from "react-icons/md";

const LINKS = [
    // {
    //     title: "Amazon Advertising",
    //     items: ["Amazon PPC", "Amazon DSP"],
    //     links: ["/services/amazon-ads", "/services/amazon-dsp"],
    // },

    // {
    //     title: "Marketplace Management",
    //     items: ["Walmart", "Etsy", "Trendyol", "Shopify"],
    //     links: ["/services/walmart-market-management", "/services/etsy-market-management", "/services/trendyol-store-management", "/services/shopify-market-management"],
    // },
    {
        title: "Dijital Reklam Yönetimi",
        items: ["Google Ads", "Meta Ads", "Twitter Ads", "LinkedIn Ads", "YouTube Ads", "TikTok Ads"],
        links: ["/services/google-ads", "/services/meta-ads", "/services/twitter-ads", "/services/linkedin-ads", "/services/youtube-ads", "/services/tiktok-ads"],
    },
    {
        title: "Amazon Mağaza Yönetimi",
        items: ["Amazon PPC", "A+ Content", "SEO", "Brand Registry","Marketplace Yönetimi"],
        links: ["/services/amazon-ads", "/services/a-plus-content", "/services/seo", "/services/brand-registry","/services/marketplace-management" ],
    },
    {
        title: "D2C Solutions",
        items: ["Website Development", "Social Media Management", "Trendyol", "Lojistik Yönetimi"],
        links: ["/services/website-management", "/services/social-media-management", "/services/trendyol-store-management", "/services/inventory-management"],

    },
    {
        title: "Kaynaklar",
        items: ["Hakkımızda", "Blog", "Referanslar", "Örnek Çalışmalar"],
        links: ["/about", "/resources/blog", "/resources/references", "/resources/case-studies"],
    }
];

export default function NewFooter({contact}) {
    return (
        <div className="contact">
            {contact && (<Form/>)}
            <footer className="footer">
                <div className="container row xl:px-0 px-6">
                    {LINKS.map((section, index) => (
                        <div key={index} className="footer-col">
                            <h4>{section.title}</h4>
                            <ul className="mb-4">
                                {section.items.map((item, itemIndex) => (
                                    <li key={itemIndex}>
                                        <Link to={section.links[itemIndex]}>
                                            {item}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                    <div className="footer-col">
                        <h4>Destek</h4>
                        <ul className="">
                            <li>
                                <Link to="#" className="!flex flex-row items-center gap-2">
                                    <RiCustomerService2Line className="w-5 h-5 text-white"/>
                                    <span> 24/7 Destek</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/contact" className="!flex flex-row items-center gap-2">
                                    <IoMailOpenOutline className="w-5 h-5 text-white"/>
                                    <span> İletişim</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/faq" className="!flex flex-row items-center gap-2">
                                    <AiOutlineQuestionCircle className="w-5 h-5 text-white"/>
                                    <span> SSS</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/terms&conditions" className="!flex flex-row items-center gap-2">
                                    <LuClipboardSignature className="w-5 h-5 text-white"/>
                                    <span>Şartlar ve Koşullar</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-col">
                        <h4>Sosyal Medya</h4>
                        <ul className="wrapper flex gap-4 mt-2">
                            <Link to="https://www.linkedin.com/company/radigital-io/about/?viewAsMember=true">
                                <li className="icon facebook">
                                    <span className="tooltip">Linkedin</span>
                                    <BsLinkedin/>
                                </li>
                            </Link>
                            {/*<Link to="/">*/}
                            {/*      <li className="icon twitter">*/}
                            {/*           <span className="tooltip">Twitter</span>*/}
                            {/*           <BsTwitter/>*/}
                            {/*      </li>*/}
                            {/*</Link>*/}
                            <Link to="https://www.instagram.com/radigitaltr/">
                                <li className="icon instagram">
                                    <span className="tooltip">Instagram</span>
                                    <BsInstagram/>
                                </li>
                            </Link>
                        </ul>
                    </div>
                    <div className="footer-col">
                        <h4>İletişim</h4>
                        <ul className="">
                            <li>
                                <Link to="tel:+905396055320" className="!flex flex-row items-center gap-2 radigitalPhone">
                                    <MdOutlinePhoneInTalk className="w-5 h-5 text-white"/>
                                    <span className="radigitalPhone">+90 539 605 53 20 </span>
                                </Link>
                            </li>
                            <li>
                                <Link to="mailto:marketing.radigital@gmail.com" className="!flex flex-row items-center gap-2">
                                    <RiMailSendLine className="w-5 h-5 text-white"/>
                                    <span className="lowercase">marketing.radigital@gmail.com</span>
                                </Link>
                            </li>
                        </ul>
                    </div>                  
                </div>
                <div className="mt-6 flex w-full flex-col items-center justify-center border-t py-4">
                    <Typography
                        variant="h6"
                        className="mb-4 text-center font-normal text-blue-gray-900 md:mb-0 text-white"
                    >
                        &copy; 2023 <Link to="https://www.radigital.com.tr/"><b
                        className="hover:text-[#7eaaff] animate-pulse transition duration-300">Ra Digital.</b>
                    </Link> All
                        Rights Reserved.
                    </Typography>
                </div>
            </footer>
        </div>
    );
}
