import React from 'react';
import ServiceDetailBanner from "../../Components/Banner/ServiceDetailBanner";
import handShake from "../../Assets/Images/story/handShake.svg";
import MainLayout from "../../Layout/MainLayout";
import ref1 from "../../Assets/Images/references/zilan.png";
import ref2 from "../../Assets/Images/references/lycia.png";
import ref3 from "../../Assets/Images/references/alessandro.png";

export function References() {
    const bannerHeader = "Referanslarımız";
    const bannerTxt = "Müşterilerimizin memnuniyetle paylaştığı başarı hikayelerini ve olumlu deneyimleri keşfedin. Bu işletmelerle işbirliği yapmaktan gurur duyuyor ve olağanüstü sonuçlar elde etmek için birlikte çalışmaktan memnuniyet duyuyoruz."
    return (
        <MainLayout>
            <ServiceDetailBanner imgLeft={true}  bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={handShake}/>

            <div className="container mx-auto my-12 p-4">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {references.map((reference, index) => (
                        <div key={index} className="flex flex-col items-center">
                            <img src={reference.image} alt={`Reference ${index + 1}`} className="w-full h-auto mb-4"/>
                            <h3 className="text-lg font-semibold mb-2 text-gray-300">{reference.company}</h3>
                            <p className="text-gray-600 text-justify">{reference.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </MainLayout>
    );
}

const references = [
    {
        image: ref1,
        company: "Zilan",
        description: "Zilan, çevrimiçi varlıklarını güçlendirmek için bizimle işbirliği yaptı. Hedefe yönelik PPC kampanyaları ve stratejik DSP yönetimi de dahil olmak üzere kapsamlı dijital reklam hizmetlerimizi kullanarak, Teknoloji İnovatörleri'nin rekabetçi piyasada dikkate değer bir büyüme ve marka görünürlüğü elde etmelerine yardımcı olduk."
    },
    {
        image: ref2,
        company: "Lycia Home",
        description: "Lycia Home, Çeşitli online platformlara odaklanarak Pazar Yönetimi konusunda bize başvurdu. Etkili optimizasyon ve stratejik yönetim aracılığıyla, E-Ticaret Girişimlerine ürünlerinin erişimini genişletmede, satışlarını artırmada ve çeşitli online pazarlarda güçlü bir varlık oluşturmada destek olduk."
    },
    {
        image: ref3,
        company: "ALESSANDRO CADAMURO",
        description: "ALESSANDRO CADAMURO, özellikle çevrimiçi reklam ve platform dışı pazarlama alanında uzmanlığımıza başvurdu. Özel stratejilerimiz ve dinamik kampanyalarımız, Dinamik İnovasyonların marka farkındalığını yükseltti ve web sitelerine önemli miktarda trafik çekerek güçlü bir çevrimiçi varlık oluşturdu."
    },
];

