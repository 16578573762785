import React from 'react';
import {useParams} from "react-router-dom";
import MainLayout from "../../Layout/MainLayout";
import CaseStudies from "./Detail/caseStudies";
import NotFound from "../404/NotFound";
import Blog from "./Detail/blog";

export const Resources = () => {
    const {resource_name} = useParams();
    let content = null;
    switch (resource_name) {
        case 'case-studies':
            content = (
                <CaseStudies/>
            )
            break;
        case 'free-ppc-audit':
            content = (
                <div>
                    <h1 className="text-white">Free PPC Audit</h1>
                </div>
            )
            break;
        case 'partners':
            content = (
                <div>
                    <h1 className="text-white">Free PPC Audit</h1>
                </div>
            )
            break;
        case 'blog':
            content = (
                <Blog/>
            )
            break;
        case 'references':
            content = (
                <div>
                    <h1 className="text-white">references</h1>
                </div>
            )
            break;

        default:
            content = (
                <NotFound/>
            );
    }
    return (
        <MainLayout>
            {/*<div className="container mx-auto ">*/}
            <div className=" ">
                {content}
            </div>
        </MainLayout>
    );
};