import React from 'react';
import { Button, Card, CardBody, CardHeader, Carousel, Typography } from "@material-tailwind/react";
import PageBanner from "../../../Components/Banner/ServiceDetailBanner";
import caseStudiesBanner from "../../../Assets/Images/story/aPlusContentBottom.svg";
import caseStudy1 from "../../../Assets/Images/serviceDetailBanner/caseStudy1.webp";
import caseStudy2 from "../../../Assets/Images/serviceDetailBanner/caseStudy2.webp";
import {
    Square3Stack3DIcon,
    PresentationChartLineIcon,
    ShareIcon
} from "@heroicons/react/24/solid";

export default function CaseStudies() {
    const bannerHeader = "Örnek Çalışmalar: Gerçek Başarı Hikayeleri";
    const bannerTxt = "İşletmelerin e-ticaret ortamında başarılı olmasına nasıl yardımcı olduğumuza dair hikayelerimizi keşfedin.";

    return (
        <div>
            <PageBanner bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={caseStudiesBanner}
                        imgLeft={true} />


            <div className="container mx-auto px-6 flex flex-col">
                <Typography color="gray" className="text-center text-2xl font-semibold my-8 ">
                    Amazon Attribution Kullanarak Off-Amazon Satışlarını ve Marka Farkındalığını Artırmak için Kullanım
                </Typography>
                <div className="flex lg:flex-row flex-col justify-between gap-4">
                    <div className="lg:w-1/2 flex flex-col gap-4 justify-center">
                        <div className="flex flex-row items-center gap-2">
                            <div className="w-[50px] h-[50px]">
                                <Square3Stack3DIcon className="w-[50px] h-[50px] text-white" />
                            </div>
                            <div className="">
                                <Typography color="white" variant="h6">Zorluk</Typography>
                                <Typography color="gray" variant="paragraph">Amazon'daki Göz Atma Görünümlerinin azalması nedeniyle ; Amazon dışı trafiği artırmak
                                    Gitar askısı ürünlerinin görünürlüğünü ve Amazon dışı satışları ve marka farkındalığını artırmak
                                </Typography>
                            </div>
                        </div>
                        <div className="flex flex-row items-center gap-2">
                            <div className="w-[50px] h-[50px]">
                                <ShareIcon className="w-[50px] h-[50px] text-white" />
                            </div>
                            <div className="">
                                <Typography color="white" variant="h6">Strateji</Typography>
                                <Typography color="gray" variant="paragraph">
                                    Google Reklamları, Instagram / Facebook, TikTok platformları aracılığıyla bilinirliği ve satışları destekleme
                                </Typography>
                            </div>
                        </div>
                        <div className="flex flex-row items-center gap-2">
                            <div className="w-[50px] h-[50px]">
                                <PresentationChartLineIcon className="w-[50px] h-[50px] text-white" />
                            </div>
                            <div className="">
                                <Typography color="white" variant="h6">Uygulama</Typography>
                                <Typography color="gray" variant="paragraph">
                                    En Çok Satan Sıralamasını İyileştirme: Artan trafik ve dönüşümler En Çok Satan Sıralamanızı artırır, bu da Amazon'da daha yüksek görünürlüğe yol açar.
                                    Rekabeti Atlamak: Dış pazarlama kanalları, kalabalık pazar yerini atlamanıza yardımcı olur ve potansiyel müşterileri doğrudan ürün sayfalarınıza yönlendirir.
                                    Satışları ve Marka Farkındalığını Artırma: Harici kaynaklar, çeşitli platformlardan olası müşterileri çeker, bu da Amazon'da daha yüksek satışlara yol açar

                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/2 flex flex-col gap-4  items-center justify-center">
                        <Card
                            shadow={false}
                            className="relative grid w-full min-h-[400px] max-w-[28rem] items-end justify-center overflow-hidden text-center"
                        >
                            <CardHeader
                                floated={false}
                                shadow={false}
                                color="transparent"
                                className="absolute inset-0 m-0 h-full w-full rounded-none bg-[url('https://img.freepik.com/free-photo/bags-with-stuff-baby_23-2147698845.jpg?w=996&t=st=1707647598~exp=1707648198~hmac=afd51528d6b916edaf15550607f5fadfe61165484bbd038ff302e51bb3065d69')] bg-cover bg-center"
                            >
                                <div
                                    className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
                            </CardHeader>
                            <CardBody className="relative py-14 px-6 md:px-12">
                                <Typography
                                    color="white"
                                    className="md:text-3xl text-xl mb-6 font-bold leading-[1.5]"
                                >
                                    Tamamen yeni bir ürünü Amazon'da piyasaya sürmek ve kurulmuş oyuncularla rekabet etmek korkutucu olabilir.
                                </Typography>
                                <Typography className="md:text-xl text-lg mb-4 text-gray-400">
                                    Bu müşteri marka bilinirliği oluşturmak, satışları artırmak ve kısıtlı bir zaman çerçevesinde kalabalık bir pazarda güçlü bir konum elde etmek zorundaydı.
                                </Typography>
                            </CardBody>
                        </Card>
                    </div>
                </div>
                <Carousel
                    className="rounded-xl pt-8"
                    loop={true}
                    navigation={({ setActiveIndex, activeIndex, length }) => (
                        <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                            {new Array(length).fill("").map((_, i) => (
                                <span
                                    key={i}
                                    className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                                        activeIndex === i ? "w-8 bg-black" : "w-4 bg-black/50"
                                    }`}
                                    onClick={() => setActiveIndex(i)}
                                />
                            ))}
                        </div>
                    )}
                >
                    <img
                        src={caseStudy1}
                        alt="case"
                        className="h-full w-full rounded-lg object-contain sm:px-12"
                    />
                    <img
                        src={caseStudy1}
                        alt="case"
                        className="h-full w-full rounded-lg object-contain sm:px-12"
                    />
                </Carousel>

                <Typography color="gray" className="text-center text-2xl font-semibold my-8">
                    Dijital Oyun Ürünleri Satıcısı, Google Reklamları ile Satışları Artırıyor
                </Typography>
                <div className="flex lg:flex-row flex-col justify-between gap-4">
                    <div className="lg:w-1/2 flex flex-col gap-4 items-center justify-center">
                        <Card
                            shadow={false}
                            className="relative grid w-full min-h-[400px] max-w-[28rem] items-end justify-center overflow-hidden text-center"
                        >
                            <CardHeader
                                floated={false}
                                shadow={false}
                                color="transparent"
                                className="absolute inset-0 m-0 h-full w-full rounded-none bg-[url('https://img.freepik.com/free-vector/growth-curve-concept-illustration_114360-4705.jpg?w=740&t=st=1707648663~exp=1707649263~hmac=955af34d11788d19065f84e541b8a7f9320876bfdd6b5fc61a82a5551f1e348b')] bg-cover bg-center"
                            >
                                <div
                                    className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
                            </CardHeader>
                            <CardBody className="relative py-14 px-6 md:px-12">
                                <Typography
                                    color="white"
                                    className="md:text-3xl text-xl mb-6 font-bold leading-[1.5]"
                                >
                                    Çevrimiçi Varlığı Artırma
                                </Typography>
                                <Typography className="md:text-xl text-lg mb-4 text-gray-400">
                                    Stratejik Google Reklamları optimizasyonu ile çevrimiçi görünürlüğü nasıl dönüştürdüğümüzü ve satışları artırdığımızı keşfedin.
                                </Typography>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="lg:w-1/2 flex flex-col gap-4 justify-center">
                        <div className="flex flex-row items-center gap-2">
                            <div className="w-[50px] h-[50px]">
                                <Square3Stack3DIcon className="w-[50px] h-[50px] text-white" />
                            </div>
                            <div className="">
                                <Typography color="white" variant="h6">Zorluk</Typography>
                                <div>
                                    <Typography color="gray" variant="paragraph">
                                        Hedef kitleye ulaşma ve web sitesi ziyaretçilerini müşterilere dönüştürme konusunda zorluklarla karşılaşıldı. Daha nitelikli trafik çekmek ve satışları artırmak için çevrimiçi reklam stratejisini geliştirmeyi amaçlıyordu.
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row items-center gap-2">
                            <div className="w-[50px] h-[50px]">
                                <ShareIcon className="w-[50px] h-[50px] text-white" />
                            </div>
                            <div className="">
                                <Typography color="white" variant="h6">Strateji</Typography>
                                <Typography color="gray" variant="paragraph">
                                    Kapsamlı anahtar kelime araştırması yapıldı, reklam kopyaları iyileştirildi ve belirli demografikler için hedeflenmiş kampanyalar başlatıldı. Optimal performans için duyarlı reklamlar geliştirildi ve dönüşüm izleme uygulandı.
                                </Typography>
                            </div>
                        </div>
                        <div className="flex flex-row items-center gap-2">
                            <div className="w-[50px] h-[50px]">
                                <PresentationChartLineIcon className="w-[50px] h-[50px] text-white" />
                            </div>
                            <div className="">
                                <Typography color="white" variant="h6">Uygulama</Typography>
                                <Typography color="gray" variant="paragraph">
                                    Google Reklamları stratejisi yürütülerek 10 ay içinde çevrimiçi satışlarda %61'lik dikkate değer bir artış elde edildi. CPA'da %20'lik bir azalma elde edildi, genel reklam maliyet etkinliğini artırarak. Marka adı anahtar kelime aramalarında %200 artış ve CPC'de %50 azalma işareti, marka farkındalığını artırarak.
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
