import React from 'react';
import {ScoolButton} from "../Buttons/ScoolButton";


export function PricingCard() {

    return (
        <div className="min-h-screen flex justify-center items-center">
            <div className="">
                <div className="text-center font-semibold">
                    <div className="flex flex-col items-center">
                        <h1 className="text-5xl font-bold gradientPattern">
                            Fiyat Planlarımız
                        </h1>
                        <div
                            className="flex w-28 mt-1 mb-10 overflow-hidden rounded bg-gradient-to-r from-[#929afe] via-[#b387f6] to-[#de67f5] h-2"></div>
                    </div>
                    <p className="text-xl text-gray-400 font-normal w-full px-8 md:w-full">
                        Sizin ve ekibiniz için en uygun planı seçin ve işinizi büyütmenin keyfini çıkarın.
                    </p>
                </div>

                <div className="bg-[#5A648D26] my-4 rounded-md">
                    <div className="container px-6 py-8 mx-auto">
                        <div className="xl:items-center xl:-mx-8 xl:flex">
                            <div className="flex flex-col items-center xl:items-start xl:ml-6">
                                <h1 className="text-2xl font-medium text-softWhite capitalize lg:text-3xl dark:text-white">
                                    Esnek Planlarımız
                                </h1>
                                <div className="mt-4">
                                    <span className="inline-block w-40 h-1 bg-[#28289e] rounded-full"></span>
                                    <span className="inline-block w-3 h-1 mx-1 bg-[#242492FF] rounded-full"></span>
                                    <span className="inline-block w-1 h-1 bg-[#242466] rounded-full"></span>
                                </div>

                                <p className="mt-4 font-medium text-gray-500 dark:text-gray-300">
                                    Planınızı Seçin, Tüm Erişimi Açın, Parlaklığı Keşfedin!
                                </p>

                                <ScoolButton
                                    link={'contact'}
                                    buttonText={'Daha Fazla Bilgi Alın'}
                                    buttonClassName="backgroundGradient text-white font-medium py-2 px-4 rounded transition-all active:scale-95 my-4"
                                    setOffset={-76}
                                />
                            </div>

                            <div className="flex-1 xl:mx-4 h-full">
                                <div
                                    className="mt-8 grid gap-6 lg:grid-cols-2 sm:grid-cols-1 lg:gap-8 md:grid-cols-2">
                                    <div
                                        className="max-w-sm mx-auto border rounded-lg md:mx-4 dark:border-gray-700 h-full">
                                        <div className="p-6">
                                            <h1 className="text-xl font-medium text-slate-300 capitalize lg:text-2xl dark:text-white">
                                                Başlangıç Website Paketi
                                            </h1>

                                            <p className="mt-4 text-gray-500 dark:text-gray-300">
                                                <b>İdeal Kullanım Alanı:</b> Shopify ve WordPress gibi platformları
                                                içeren Başlangıç Website Paketi, hızla çevrimiçi varlık oluşturmak
                                                isteyen
                                                küçük işletmeler veya başlangıçlar için maliyeti etkin bir çözümdür.
                                            </p>

                                            <h2 className="mt-4 text-2xl font-semibold text-slate-200 sm:text-3xl dark:text-gray-300">
                                                {/*TL İŞARETİ*/}
                                                299.99₺
                                                <span className="text-base font-medium ">/Sayfa Başı</span>
                                            </h2>

                                            <p className="mt-1 text-slate-300">
                                                Tek Seferlik Ödeme
                                            </p>

                                            <button
                                                className="w-full px-4 py-2 mt-6 tracking-wide text-white capitalize transform bg-[#28289e] rounded-md hover:bg-[#242492FF] focus:outline-none focus:bg-[#28289e] active:scale-105 transition duration-300 active:duration-300">
                                                Başlangıç Yap, Hızlı Etki Yarat!
                                            </button>
                                        </div>

                                        <hr className="border-gray-200 dark:border-gray-700"/>

                                        <div className="lg:p-6 p-3">
                                            <h1 className="text-lg font-medium text-gray-700 capitalize lg:text-xl dark:text-white">
                                                Neler Dahil:
                                            </h1>

                                            <div className="mt-4 space-y-4 lg:h-[275px] md:h-[285px] h-max">
                                                <div className="flex items-center gap-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         className="w-5 h-5 text-blue-500" viewBox="0 0 20 20"
                                                         fill="currentColor">
                                                        <path fillRule="evenodd"
                                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                              clipRule="evenodd"/>
                                                    </svg>

                                                    <span className="text-gray-600">
                                                        Sınırlı Özelleştirme
                                                    </span>
                                                </div>

                                                <div className="flex items-center gap-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         className="w-5 h-5 text-blue-500" viewBox="0 0 20 20"
                                                         fill="currentColor">
                                                        <path fillRule="evenodd"
                                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                              clipRule="evenodd"/>
                                                    </svg>

                                                    <span className="text-gray-600">
                                                    Hızlı kullanım
                                                    </span>
                                                </div>

                                                <div className="flex items-center gap-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         className="w-5 h-5 text-blue-500" viewBox="0 0 20 20"
                                                         fill="currentColor">
                                                        <path fillRule="evenodd"
                                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                              clipRule="evenodd"/>
                                                    </svg>

                                                    <span
                                                        className="text-gray-600">
                                                        E-ticaret Özellikleri
                                                    </span>
                                                </div>

                                                <div className="flex items-center gap-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         className="w-5 h-5 text-blue-500" viewBox="0 0 20 20"
                                                         fill="currentColor">
                                                        <path fillRule="evenodd"
                                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                              clipRule="evenodd"/>
                                                    </svg>

                                                    <span className="text-gray-600">
                                                        Bütçe Dostu Çözüm
                                                    </span>
                                                </div>

                                                <div className="flex items-center gap-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         className="w-5 h-5 text-red-400" viewBox="0 0 20 20"
                                                         fill="currentColor">
                                                        <path fillRule="evenodd"
                                                              d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                                                              clipRule="evenodd"/>
                                                    </svg>

                                                    <span className="text-gray-600">
                                                        Şablon Tasarım Kısıtlamaları
                                                    </span>
                                                </div>

                                                <div className="flex items-center gap-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         className="w-5 h-5 text-red-400" viewBox="0 0 20 20"
                                                         fill="currentColor">
                                                        <path fillRule="evenodd"
                                                              d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                                                              clipRule="evenodd"/>
                                                    </svg>

                                                    <span className="text-gray-600">
                                                        Revize ve Geliştirme Zorlukları
                                                    </span>
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         className="w-5 h-5 text-red-400" viewBox="0 0 20 20"
                                                         fill="currentColor">
                                                        <path fillRule="evenodd"
                                                              d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                                                              clipRule="evenodd"/>
                                                    </svg>

                                                    <span className="text-gray-600">
                                                        Kısıtlı Özelleştirme
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="max-w-sm mx-auto border rounded-lg md:mx-4 dark:border-gray-700 h-full">
                                        <div className="p-6">
                                            <h1 className="text-xl font-medium text-slate-300 capitalize lg:text-2xl dark:text-white">
                                                Premium Website Paketi
                                            </h1>

                                            <p className="mt-4 text-gray-500 dark:text-gray-300">
                                                <b>İdeal Kullanım Alanı: </b>
                                                Premium Website Paketi, özel kodlama ile birlikte, özel ve etkileyici
                                                bir çevrimiçi varlık arayan kurumsal işletmeler için idealdir. Bu paket,
                                                markanızın benzersizliğini vurgulamak için özel olarak tasarlanmış ve
                                                geliştirilmiş özellikler sunar.

                                            </p>

                                            <h2 className="mt-4 text-2xl font-semibold text-slate-200 sm:text-3xl dark:text-gray-300">
                                                2500.00₺
                                                <span className="text-base font-medium ">/5 Sayfaya Kadar</span>
                                            </h2>

                                            <p className="mt-1 text-slate-300">
                                                Tek Seferlik Ödeme
                                            </p>

                                            <button
                                                className="w-full px-4 py-2 mt-6 tracking-wide text-white capitalize transform bg-[#28289e] rounded-md hover:bg-[#242492FF] focus:outline-none focus:bg-[#28289e] active:scale-105 transition duration-300 active:duration-300">
                                                Premium Başlangıç, Mükemmelliği Yükselt!
                                            </button>
                                        </div>

                                        <hr className="border-gray-200 dark:border-gray-700"/>

                                        <div className="lg:p-6 p-3">
                                            <h1 className="text-lg font-medium text-gray-700 capitalize lg:text-xl dark:text-white">
                                                Neler Dahil:
                                            </h1>

                                            <div className="mt-4 space-y-4 lg:h-[275px] md:h-[285px] h-max">
                                                <div className="flex items-center gap-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         className="w-5 h-5 text-blue-500" viewBox="0 0 20 20"
                                                         fill="currentColor">
                                                        <path fillRule="evenodd"
                                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                              clipRule="evenodd"/>
                                                    </svg>

                                                    <span className="text-gray-600">
                                                         Sınırsız Özelleştirme
                                                    </span>
                                                </div>

                                                <div className="flex items-center gap-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         className="w-5 h-5 text-blue-500" viewBox="0 0 20 20"
                                                         fill="currentColor">
                                                        <path fillRule="evenodd"
                                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                              clipRule="evenodd"/>
                                                    </svg>

                                                    <span className="text-gray-600">
                                                        Özel Kodlama ve Performans
                                                    </span>
                                                </div>

                                                <div className="flex items-center gap-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         className="w-5 h-5 text-blue-500" viewBox="0 0 20 20"
                                                         fill="currentColor">
                                                        <path fillRule="evenodd"
                                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                              clipRule="evenodd"/>
                                                    </svg>

                                                    <span className="text-gray-600">
                                                        Özel Tasarım ve Geliştirme
                                                    </span>
                                                </div>

                                                <div className="flex items-center gap-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         className="w-5 h-5 text-blue-500" viewBox="0 0 20 20"
                                                         fill="currentColor">
                                                        <path fillRule="evenodd"
                                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                              clipRule="evenodd"/>
                                                    </svg>

                                                    <span
                                                        className="text-gray-600">
                                                        Özel Tasarım
                                                    </span>
                                                </div>

                                                <div className="flex items-center gap-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         className="w-5 h-5 text-red-400" viewBox="0 0 20 20"
                                                         fill="currentColor">
                                                        <path fillRule="evenodd"
                                                              d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                                                              clipRule="evenodd"/>
                                                    </svg>

                                                    <span className="text-gray-600">
                                                      Geliştime Süresi
                                                    </span>
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         className="w-5 h-5 text-red-400" viewBox="0 0 20 20"
                                                         fill="currentColor">
                                                        <path fillRule="evenodd"
                                                              d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                                                              clipRule="evenodd"/>
                                                    </svg>

                                                    <span className="text-gray-600">
                                                      E-ticaret Özellikleri
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}