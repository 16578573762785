import React from 'react';
import {useParams} from "react-router-dom";
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import amazonAds from "../../../Assets/Images/story/business.svg"
import {motion} from "framer-motion";
import {
    Timeline, TimelineBody,
    TimelineConnector,
    TimelineHeader,
    TimelineIcon,
    TimelineItem,
    Typography
} from "@material-tailwind/react";
import {FaRegLightbulb} from "react-icons/fa";
import {BsSpeedometer2} from "react-icons/bs";
import {MdOutlineAnalytics} from "react-icons/md";
import experts from "../../../Assets/Images/story/experts.png";
import bannersAds from "../../../Assets/Images/banners/Amazon PPC.webp";


export default function AmazonAds() {
    let {service_name} = useParams();
    service_name = "amazon-ads" ? "Amazon Reklamları (PPC)" : "Amazon Reklamları (PPC)";
    const bannerTxt = "Amazon Reklamlarıyla Başarıya Doğru! Kampanyalarınızı mükemmel şekilde yönetin, görünürlüğünüzü artırın ve doğal arama sonuçlarınızı etkilemeden büyüyün. Bizimle başarıya koşun!"
    // "Cultivating Success with Amazon Advertising The way you organize, oversee, and fine-tune your campaigns can make all the difference in achieving optimal visibility, scalability, and ensuring that your ad sales don't compromise your organic rankings.";
    return (
        <div>
            <ServiceDetailBanner service_name={service_name} bannerTxt={bannerTxt} bannerImg={bannersAds}/>
            <section className="py-6">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className=" mx-auto flex flex-col justify-center items-center ">
                    <div className="flex flex-col justify-center items-center gap-3 px-6 max-w-6xl">
                        <Typography color="white" variant="h3" className="nbXs:text-center">
                            Amazon Reklamları: Ürünlerinizi Dünya ile Buluşturun!
                        </Typography>
                        <Typography color="white" variant="paragraph">
                            Amazon, global çapta milyonlarca müşteriye ulaşmanın en etkili yollarından biridir. Ancak bu
                            geniş pazarda dikkat çekmek ve satışları artırmak için doğru stratejiler gerekmektedir.
                            Amazon reklamları, ürünlerinizi hedef kitlenize daha etkili bir şekilde tanıtmak ve
                            satışlarınızı artırmak için güçlü bir araçtır.
                        </Typography>
                    </div>
                </motion.div>
            </section>

            <section className="py-6 flex justify-center ">
                <div className="xl:w-[55%] lg:w-[50%] md:w-[65%] p-8 shadow-basicShadow">
                    <Typography color="white" variant="h4" className="nbXs:text-center pb-3">
                        Amazon Reklamlarının Avantajları:
                    </Typography>
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="p-2">
                                    <FaRegLightbulb className="h-4 w-4"/>
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Amazon Reklamlarının Avantajları
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            Hedefli Görünürlük: Ürünleriniz, potansiyel müşterilerinizin aramalarında üst sıralarda ve ilgili kategorilerde görüntülenir.
                                        </li>
                                        <li>
                                            Yüksek Dönüşüm Oranları: Hedeflenmiş reklamlar, daha fazla tıklama ve satın alma dönüşümü sağlar.
                                        </li>
                                        <li>
                                            Verimli Bütçe Yönetimi: Reklam bütçenizi optimize ederek en iyi dönüşü elde etmenizi sağlar.
                                        </li>
                                        <li>
                                            Gerçek Zamanlı Analiz ve Optimizasyon: Reklam performansınızı anlık olarak izleyebilir ve stratejilerinizi buna göre ayarlayabilirsiniz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="p-2">
                                    <BsSpeedometer2 className="h-4 w-4"/>
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Bizimle Amazon Reklamlarınızı Yönetin
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gray" className="font-normal text-gray-600">
                                 <ul className="list-disc pl-6">
                                     <li>
                                         Stratejik Planlama: Hedef kitlenizi belirleyerek ve rekabet analizi yaparak en uygun reklam stratejisini oluşturuyoruz.
                                     </li>
                                     <li>
                                         Reklam Kampanyaları Yönetimi: Amazon PPC (Ücretli Tıklama) kampanyalarınızı profesyonel bir şekilde yönetiyoruz.
                                     </li>
                                     <li>
                                         Anahtar Kelime Optimizasyonu: Doğru anahtar kelimeleri seçerek reklamlarınızın daha geniş bir kitleye ulaşmasını sağlıyoruz.
                                     </li>
                                     <li>
                                         Performans Analizi ve Raporlama: Reklam performansınızı düzenli olarak izleyerek verimliliği artırıyoruz.
                                     </li>
                                 </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineHeader>
                                <TimelineIcon className="p-2">
                                    <MdOutlineAnalytics className="h-4 w-4"/>
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Neden Bizi Tercih Etmelisiniz?
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            Uzmanlık ve Deneyim: Amazon reklamları konusunda uzman ekibimizle size en iyi hizmeti sunuyoruz.
                                        </li>
                                        <li>
                                            Sonuç Odaklı Çalışma: Reklam performansınızı artırarak satışlarınızı yükseltmek için stratejik bir yaklaşım benimsiyoruz.                                        </li>
                                        <li>
                                            Müşteri Memnuniyeti: Müşterilerimizin başarısını ön planda tutarak uzun vadeli iş birlikleri kuruyoruz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                        Daha Fazla Satış İçin Amazon Reklamlarıyla Tanışın!
                    </Typography>
                    <div className="lg:px-6 lg:w-[55%] w-full">
                        <div className="flex flex-col justify-center items-center gap-3 w-full">
                            <Typography color="white" variant="h3"
                                        className="lg:block hidden text-center nbXs:text-center pb-3">
                                Daha Fazla Satış İçin Amazon Reklamlarıyla Tanışın!
                            </Typography>
                            <Typography variant="lead" className="text-slate-300 shadowText">
                                Amazon reklamlarıyla ilgili daha fazla bilgi almak ve satışlarınızı artırmak için bizimle iletişime geçin. Markanızı dünya ile buluşturmanın zamanı geldi!
                            </Typography>
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-full max-h-[325x] nbXs:max-h-[200px]">
                        <img src={experts} alt="experts"
                             className="w-full max-h-[325px] nbXs:max-h-[200px] object-cover drop-shadow-xl"/>
                    </div>
                </motion.div>

            </section>
        </div>
    )
}