import React from 'react';
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import trendyolStoreManagementBanner from "../../../Assets/Images/story/growingMoney.svg";
import {motion} from "framer-motion";
import {
    Timeline, TimelineBody, TimelineConnector, TimelineHeader, TimelineIcon, TimelineItem, Typography
} from "@material-tailwind/react";
import trendyolOptimizationImg from "../../../Assets/Images/story/improve.png";
import {useParams} from "react-router-dom";
import bannersTrendyol from "../../../Assets/Images/banners/Trenyol Store Management.webp";

export default function TrendyolStoreManagement() {
    let {service_name} = useParams();
    service_name = "trendyol_store_management" ? "Trendyol Mağaza Yönetimi" : "Trendyol Mağaza Yönetimi";
    const bannerTxt = "Trendyol mağazanızın potansiyelini Ra Digital ile maksimize edin. Özel hizmetlerimiz Trendyol hesabınızı optimize eder, görünürlüğü artırır ve satışları artırır. Trendyol platformunda başarılı bir varlık için özel çözümlerimizi keşfedin.";

    return (
        <div>
            <ServiceDetailBanner service_name={service_name} bannerTxt={bannerTxt} bannerImg={bannersTrendyol}/>
            <section className="py-6 flex justify-center ">
                <div className="xl:w-[55%] lg:w-[50%] md:w-[65%] p-8 shadow-basicShadow">
                    <Typography color="white" variant="h4" className="nbXs:text-center pb-3">
                        Trendyol Mağaza Optimizasyonu
                    </Typography>
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    1
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Mağaza Geliştirme
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            Trendyol mağazanızı maksimum etki için yükseltin. Mağaza geliştirme
                                            hizmetlerimiz, ürünlerinizin etkili bir şekilde sergilendiğinden emin olur,
                                            potansiyel müşterilerin ilgisini arttırır.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    2
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Ürün Listeleme Optimizasyonu
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            Trendyol ürün listelerinizi etkili stratejilerle optimize edin. Ürün
                                            listelerinizi Trendyol'un arama algoritmalarıyla uyumlu hale getirerek,
                                            ürünlerinizin maksimum görünürlük kazanmasını sağlarız.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    3
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Trendyol SEO Stratejileri
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            Ürünlerinizin keşfedilebilirliğini artırmak için etkili SEO stratejileri
                                            kullanın. Ürün listelerinizi Trendyol'un arama algoritmalarıyla uyumlu hale
                                            getirerek, ürünlerinizin öne çıkmasını sağlarız.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                        Trendyol Mağaza Yönetimi için Neden Ra Digital'i Tercih Etmelisiniz?
                    </Typography>
                    <div className="lg:px-6 lg:w-[55%] w-full">
                        <div className="flex flex-col justify-center items-center gap-3 w-full">
                            <Typography color="white" variant="h3"
                                        className="lg:block hidden text-center nbXs:text-center pb-3">
                                Trendyol Mağaza Yönetimi için Neden Ra Digital'i Tercih Etmelisiniz?
                            </Typography>
                            <Typography color="white" variant="paragraph">
                                <ul className="list-disc">
                                    <li>
                                        Trendyol Uzmanlığı: Ekibimiz, Trendyol mağaza hesaplarını yönlendirmekte ve
                                        optimize etmekte uzmanlaşmıştır.
                                    </li>
                                    <li>
                                        Kapsamlı Çözümler: Mağaza geliştirme ve SEO stratejilerinden başlayarak,
                                        Trendyol platformundaki başarınızı sağlamak için kapsamlı bir yaklaşım
                                        sunuyoruz.
                                    </li>
                                    <li>
                                        Özelleştirilmiş Stratejiler: Her markanın benzersizliğini tanıyarak,
                                        hizmetlerimizi belirli hedefleriniz ve marka kimliğinizle uyumlu olacak şekilde
                                        özelleştiriyoruz.
                                    </li>
                                </ul>
                            </Typography>
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-full max-h-[325x] nbXs:max-h-[200px]">
                        <img src={trendyolOptimizationImg} alt="Trendyol Optimization"
                             className="w-full max-h-[325px] nbXs:max-h-[200px] object-cover drop-shadow-xl xl:object-cover object-contain"/>
                    </div>
                </motion.div>
            </section>

            <section
                className="bg-gradient-to-r from-[#64748b] via-[#414e63] to-[#202c3e] py-8 rounded-md shadow-2xl mb-6">
                <div className="flex flex-col justify-center items-center gap-3 lg:px-6 px-2 max-w-6xl mx-auto">
                    <Typography color="white" variant="h4"
                                className="text-center nbXs:text-center pb-3 italic lg:text-xl text-lg nbXs:text-base">
                        "Markanızın Trendyol'daki varlığını yükseltmeye hazır mısınız? Ra Digital ile iletişime geçin ve
                        kapsamlı hizmetlerimizin Trendyol mağazanızın tam potansiyelini ortaya çıkarabileceğini,
                        satışları artırabileceğini ve Trendyol platformunda kalıcı başarıyı sağlayabileceğini keşfedin."
                    </Typography>
                </div>
            </section>
        </div>
    );
}
