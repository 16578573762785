import React from 'react';
import MainLayout from "../Layout/MainLayout";
import {Typography} from "@material-tailwind/react";
import {CustomMarque} from "../Components/Extra/Marque";
import {ScoolButton} from "../Components/Buttons/ScoolButton";
import {LinkButton} from "../Components/Buttons/CustomLinkButton";
import {motion} from "framer-motion";
import bannersHomePage from "../Assets/Images/banners/Anasayfa Transparent (2).webp";

export default function HomePage() {
    return (
        <MainLayout>
            {/*
            <section>
                <ShuffleHero/>
            </section>
            */}

            <section className="w-full grid grid-cols-1 md:grid-cols-2 items-center gap-8 px-6 max-w-6xl mx-auto py-12">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                >
                    {/*<span className="block mb-4 text-xs md:text-sm text-indigo-500 font-medium">*/}
                    {/*  Better every day*/}
                    {/*</span>*/}
                    <h3 className="text-4xl md:text-6xl font-semibold gradientPattern">
                        {/*We’re the Growth Engine for Your Brand.*/}
                        Markanızın Güçlü Destekçisi
                    </h3>
                    <p className="text-base md:text-lg text-textWhite my-4 md:my-6 shadowText">
                        Google ve Meta uzmanlarından oluşan bir ekibiz ve markaların işlerini büyütmek için Google ve
                        Meta'da çalışıyoruz. Yüzlerce markanın işlerini Google ve Meta'da büyütmelerine yardımcı olduk
                        ve sizin de yardımcı olabiliriz.
                    </p>
                    <ScoolButton
                        link={'contact'}
                        buttonText={'Ücretsiz Hesap Analizinizi Yapalım'}
                        buttonClassName="backgroundGradient text-white font-medium py-2 px-4 rounded transition-all active:scale-95"
                        setOffset={-76}
                    />

                </motion.div>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="bg-transparent">
                    <img src={bannersHomePage} alt="RaDigital" className="rounded-lg"/>
                </motion.div>

                {/*<div className="bg-mainColor z-10">*/}
                {/*    <motion.video*/}
                {/*        initial={{opacity: 0, y: 20}}*/}
                {/*        animate={{opacity: 1, y: 0}}*/}
                {/*        transition={{duration: 0.6}}*/}
                {/*        className="h-max w-full rounded-lg" controls  >*/}
                {/*        <source src={demo} type="video/mp4" />*/}
                {/*        Your browser does not support the video tag.*/}
                {/*    </motion.video>*/}
                {/*</div>*/}
            </section>
            <section className="py-6">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className=" mx-auto flex flex-col justify-center items-center ">
                    <div className="flex flex-col justify-center items-center gap-3 px-6 max-w-6xl">
                        <Typography color="white" variant="h3" className="text-textWhiteHead font-montserrat">
                            Uzun Vadeli Hedeflerle Başarılar
                        </Typography>
                        <Typography color="white" variant="lead" className="text-textWhite font-montserrat">
                            Amazon markanızın iş sonuçlarını geliştirmede mükemmeliz. Dijital pazarlama, PPC yönetimi,
                            yaratıcı hizmetler, müşteri deneyimi, Google Ads, Meta Ads ve İş Ortağı Pazarlamayı içeren
                            iyi kurulmuş bir stratejiyle başarı elde ediyoruz. Yaklaşımımız, son teknolojiyle
                            güçlendirilmiş ve dünyanın dört bir yanından gelen parlak pazarlama uzmanlarından oluşan bir
                            ekip tarafından desteklenmektedir.
                        </Typography>
                    </div>
                </motion.div>
                <CustomMarque/>
                <div className="container mx-auto flex flex-col justify-center items-center gap-3 md:w-2/3">
                    {/*<Button color="black" variant="outlined" ripple="light" className="md:w-1/3">*/}
                    {/*    Learn More*/}
                    {/*</Button>*/}
                    <LinkButton buttonText={"Daha Fazlası İçin Hizmetlerimize Göz Atın"} link={"#"} setOffset={-75}
                                buttonClassName={"backgroundGradient"}/>
                </div>
            </section>
            <section className="bg-mainColor py-10">
                <div
                    className="container mx-auto flex flex-col justify-center items-center gap-3 md:w-2/3 sm:px-0 px-3">
                    <Typography color="white" variant="h3" className="text-center">
                        Güçlü Uzmanlığımızla Dijital Pazarlama Stratejinizi Güçlendirin
                    </Typography>
                    <Typography color="white" variant="paragraph" className="tracking-wider">
                        Dijital pazarlama dünyasında yapay zeka araçları ön planda olsa da, pazarlama dinamikleri ve
                        piyasa yönetimi sürekli değişiyor. Müşteri ve piyasa ihtiyaçlarına hızlıca adapte olmak için
                        deneyimli ekibimizle hemen harekete geçin!
                    </Typography>
                    <div className="grid grid-cols-2 nbXs:grid-cols-1 py-4 gap-12 tracking-wide">
                        <Typography color="white" variant="text">
                            <li className="list-disc">
                                Markanızın varlığını genişletmek ve Amazon ekosistemi içinde ve dışında genel satışları
                                artırmak için büyüme motoru çözümlerimizi kullanın.
                            </li>
                        </Typography>

                        <Typography color="white" variant="text">
                            <li className="list-disc">
                                Ekiplerimiz arasında karşılıklı güven ve saygıyı teşvik ediyoruz, değerlerimizi
                                organizasyonumuz boyunca hizalamak için güçlü bir ortaklık sağlıyoruz.
                            </li>
                        </Typography>
                        <Typography color="white" variant="text">
                            <li className="list-disc">
                                Veri-odaklı yaklaşımımız oyun değiştirici niteliktedir. Stratejinizi oluşturmadan önce
                                verilerinizi titizlikle test edip doğruluyoruz.
                            </li>
                        </Typography>
                        <Typography color="white" variant="text">
                            <li className="list-disc">
                                İhtiyaç duyduğunuz her an ekibimizin uzmanlarına sınırsız erişim sağlayan, kendinize
                                özel kanal uzmanlarımızla sorunsuz iletişimin tadını çıkarın.
                            </li>
                        </Typography>

                    </div>

                </div>
            </section>


        </MainLayout>
    )
}