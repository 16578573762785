import React from 'react';
import MainLayout from '../../Layout/MainLayout';
import {motion} from 'framer-motion';
import team from '../../Assets/Images/story/team.svg';
import {BiLogoAmazon} from "react-icons/bi";
import {MdCampaign} from "react-icons/md";
import {SiManageiq} from "react-icons/si";
import {Typography} from "@material-tailwind/react";

export default function AboutPage() {
    return (
        <MainLayout>
            <motion.section
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.6}}
                className="py-6"
            >
                <div className="max-w-6xl mx-auto">
                    <div className="flex flex-row gap-4">
                        <div className="w-full px-4 mb-10 lg:w-2/3 lg:mb-0 lg:flex lg:items-center">
                            <div className="lg:max-w-md">
                                <div className="px-4 pl-4 mb-6 border-l-4 border-[#503DFFFF]">
                                    <span
                                        className="text-sm text-gray-500 uppercase dark:text-gray-400">Biz Kimiz?</span>
                                    <h1 className="mt-2 text-3xl font-black text-gray-300 md:text-5xl dark:text-gray-300">
                                        Ra Digital Hakkında
                                    </h1>
                                </div>
                                <p className="px-4 mb-3 text-base leading-7 text-gray-400 dark:text-gray-400">
                                    Ra Digital'de, yenilik reklamın etki alanıyla buluşuyor. Biz sadece bir reklam
                                    ajansı değiliz; iş büyümesi ve başarı yolculuğunda sizin için adanmış bir
                                    ortağınızız.
                                </p>
                            </div>
                        </div>
                        <div
                            className="md:relative absolute md:opacity-100 opacity-20  w-full px-4 mb-3 lg:w-1/3 lg:mb-0 flex items-center justify-center">
                            <img
                                src={team}
                                alt=""
                                className="object-cover w-full h-full rounded mx-auto transform transition-transform md:hover:scale-105 duration-300"
                            />
                        </div>
                    </div>
                </div>
            </motion.section>

            <motion.section
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.6}}
                className="max-w-6xl mx-auto px-4 mx-auto text-center">
                <h2 className="text-2xl font-bold text-gray-300 mb-3 lg:text-5xl dark:text-gray-400">
                    Biz Kimiz?
                </h2>

                <p className="pb-10 mb-8 text-lg text-gray-400 border-b border-gray-200 dark:border-gray-600 dark:text-gray-400 md:text-xl">
                    Ra Digital'de yenilik reklamın etki alanıyla buluşuyor. Radigital.io'da, biz
                    sadece bir reklam ajansı değiliz; iş büyümesi ve başarı yolculuğunda sizin için adanmış bir
                    ortağınızız.
                </p>

                <div className="mx-auto md:max-w-6xl mx-auto">
                    <p className="pb-10 mb-8 text-lg text-gray-400 border-b border-gray-200 dark:border-gray-600 dark:text-gray-400 md:text-xl">
                        Ra Digital'de, başarınızın bizim başarımız olduğunun farkındayız. Ürünlerinizin ve reklam
                        kampanyalarınızın yönetiminde kapsamlı yardım sağlama konusunda kararlıyız. Yaklaşımımız,
                        hedeflerimizi iş hedeflerinizle uyumlu hale getirmeye odaklanmış olup, optimal büyüme ve
                        karlılık oranlarını sağlamayı amaçlamaktadır.
                    </p>

                    <h2 className="mb-4 text-2xl font-semibold text-gray-400 md:text-3xl dark:text-gray-300">
                        Sadece Tanıtımdan Fazlası
                    </h2>
                    <p className="mb-4 text-base text-gray-400 md:text-lg dark:text-gray-400">
                        Biz reklamı sadece bir tanıtım olarak görmüyoruz; kendi mağazamızın işleyişi gibi detaylı olarak
                        raporlanmış ve düzenlenmiş bir girişim olarak görüyoruz. Grafik tasarımcılarımız,
                        portföyünüzdeki ürünleri titizlikle inceler ve ürün görüntüleri hakkında değerli içgörüler
                        sunar. Aynı anda, mühendis ve istatistikçi ekibimiz reklamlarınızı kapsamlı bir şekilde analiz
                        ederek, özel ihtiyaçlarınıza uygun en uygun kampanya türlerini ve teklif stratejilerini
                        belirler.
                    </p>

                    <div className="flex items-center justify-center pt-6">
                        <div className="px-4 pt-6 mx-auto max-w-7xl">
                            <div className="max-w-xl mx-auto">
                                <div className="text-center ">
                                    <div className="relative flex flex-col items-center ">
                                        <div
                                            className="absolute hidden md:block -top-14 left-0 text-[120px] text-gray-100 font-bold opacity-10"
                                        >
                                            RaDigital
                                        </div>
                                        <h1 className="text-5xl font-bold gradientPattern"> Özelliklerimiz</h1>
                                        <div
                                            className="flex w-28 mt-1 mb-10 overflow-hidden rounded bg-gradient-to-r from-[#929afe] via-[#b387f6] to-[#de67f5] h-2"></div>
                                    </div>
                                    <p className="mb-16 text-base text-center text-gray-500">
                                        Ra Digital sadece bir reklam ajansı değil; iş büyümenizde adanmış bir
                                        ortağınızız. Yaratıcı ekibimiz yenilikçi stratejiler ve son teknolojiyi
                                        kullanarak etkileyici kampanyalar oluşturur. Başarıyı sizin kilometre
                                        taşlarınıza göre ölçüyor, markanızın öne çıkmasını sağlıyoruz. Zorlukları
                                        zaferlere dönüştüren işbirlikçi bir yolculuk için bizimle ortak olun.
                                    </p>
                                </div>
                            </div>
                            <div
                                className="grid grid-cols-1 gap-x-4 gap-y-6 lg:gap-x-8 lg:gap-y-8 md:grid-cols-2 lg:grid-cols-3">
                                <div
                                    className="w-full p-8 mb-5 text-center transition-all bg-[rgba(5,2,19,0.76)] rounded shadow dark:bg-gray-700 hover:shadow-lg">
                                    <div
                                        className="inline-block p-4 mb-6 -mt-16 bg-[rgba(242,248,249,0.4)] rounded-full">
                                        <BiLogoAmazon className="h-8 w-8 text-white"/>
                                    </div>
                                    <h3 className="mb-4 text-2xl font-semibold text-slate-200">
                                        Amazon Reklam Uzmanlığı
                                    </h3>
                                    <p className="text-base text-slate-400">
                                        Amazon Reklam uzmanlığımızın gücünü kullanın. Amazon platformuna özel olarak
                                        hazırlanan stratejik kampanyalar oluşturmada uzmanlaştık, ürünlerinizin
                                        rekabetin arasında parlamasını sağlıyoruz. Sponsorlu ürünlerden gösterim
                                        reklamlarına, her yönüyle optimize ediyor ve ROI'nızı maksimize ediyoruz.
                                    </p>
                                </div>
                                <div
                                    className="w-full p-8 mb-5 text-center transition-all bg-[rgba(5,2,19,0.76)] rounded shadow dark:bg-gray-700 hover:shadow-lg"
                                >
                                    <div
                                        className="inline-block p-4 mb-6 -mt-16 bg-[rgba(242,248,249,0.4)] rounded-full">
                                        <MdCampaign className="h-8 w-8 text-white"/>
                                    </div>
                                    <h3 className="mb-4 text-2xl font-semibold text-slate-200">
                                        Çok Kanallı Kampanya Yönetimi
                                    </h3>
                                    <p className="text-base text-slate-400">
                                        Çok kanallı kampanya yönetimi ile farklı platformlarda erişiminizi genişletin.
                                        Google Ads, Meta Ads veya diğer büyük e-ticaret platformları olsun, tutarlı
                                        reklam stratejileri düzenliyoruz. Hedef kitlenize nerede olurlarsa olsunlar
                                        ulaşın ve küresel ölçekte etkileşimi artırın.
                                    </p>
                                </div>
                                <div
                                    className="w-full p-8 mb-5 text-center transition-all bg-[rgba(5,2,19,0.76)] rounded shadow dark:bg-gray-700 hover:shadow-lg"
                                >
                                    <div
                                        className="inline-block p-4 mb-6 -mt-16 bg-[rgba(242,248,249,0.4)] rounded-full">
                                        <SiManageiq className="h-8 w-8 text-white"/>
                                    </div>
                                    <h3 className="mb-4 text-2xl font-semibold text-slate-200">
                                        Kapsamlı Pazar Yönetim Paketi
                                    </h3>
                                    <p className="text-base text-slate-400">
                                        Çevrimiçi varlığınızı kapsamlı pazar yönetim paketimizle yükseltin. Walmart ve
                                        Etsy'den Shopify'e kadar, uçtan uca çözümler sunuyoruz. Hizmetlerimiz A+ içerik
                                        oluşturma, marka kaydı desteği, sosyal medya yönetimi ve web sitesi
                                        optimizasyonunu içerir. Farklı pazar yerlerinde sorunsuz entegrasyon ve yönetim
                                        deneyimi yaşayın.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mx-auto md:max-w-6xl mx-auto px-3">
                        <Typography color="white" variant="h3">
                            Bizimle çalışmanın avantajlarından yararlanın:
                        </Typography>

                        <Typography color="white" variant="paragraph"
                                    className="text-start w-full flex items-center justify-center">
                            <ul className="list-disc">
                                <li>
                                    İnovatif reklam stratejileri: Sektörünüzde öne çıkmanızı sağlayacak yenilikçi
                                    yaklaşımlarla dikkat çekin.
                                </li>
                                <li>
                                    Müşteri odaklı yaklaşım: İhtiyaçlarınızı anlıyor ve çözümlerimizi iş
                                    hedeflerinize uygun şekilde şekillendiriyoruz.
                                </li>
                                <li>
                                    Optimal büyüme ve karlılık: Reklam yatırımlarınızdan en yüksek verimi almanız
                                    için stratejiler geliştiriyoruz.
                                </li>
                                <li>
                                    Yaratıcı çözümler ve ileri teknoloji: Sektördeki en son teknolojiyi ve yaratıcı
                                    fikirleri kullanarak markanızı güçlendiriyoruz.
                                </li>
                                <li>
                                    Güçlü iş ortaklıkları ve uzun vadeli başarı: Sizinle güçlü bir iş birliği
                                    kurarak uzun vadeli başarı için birlikte çalışıyoruz.
                                </li>
                            </ul>
                        </Typography>
                    </div>

                    <div className="p-6 my-4 bg-[#33415577] rounded-xl hover:animate-pulse transition duration-500">
                        <p className="mb-4 text-xl leading-tight text-slate-200 dark:text-gray-300 md:text-2xl italic">
                            "Ra Digital ile başarıya giden yolculuğunuzda size destek olmak için buradayız. İnovasyon, etki
                            ve başarı için bize katılın!"
                        </p>
                    </div>
                </div>
            </motion.section>

        </MainLayout>
    );
}
