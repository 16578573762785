import React from 'react';
import MainLayout from "../../Layout/MainLayout";
import { Link } from "react-router-dom";

export const TermsAndConditions = () => {
    return (
        <MainLayout>
            <div className="container mx-auto p-4 !my-4 text-white bg-blue-gray-500/10">
                <h1 className="text-3xl font-bold mb-4">Kullanım Koşulları</h1>

                <section>
                    <h2 className="text-xl font-bold mb-2">Yorum ve Tanımlar</h2>

                    <p className="mb-4">
                        <strong>Yorum</strong>
                    </p>
                    <p>Baş harfi büyük olan kelimeler, aşağıdaki koşullar altında tanımlanan anlamlara sahiptir.</p>
                    <p>
                        Aşağıdaki tanımlar, tekil veya çoğul olmalarına bakılmaksızın aynı anlama sahiptir.
                    </p>

                    <h2 className="text-xl font-bold mb-2">Tanımlar</h2>

                    <p>Bu Kullanım Koşulları için aşağıdaki tanımlar geçerlidir:</p>

                    <ul className="list-disc ml-8">
                        <li>
                            <strong>İştirakçi</strong>: Bir tarafı kontrol eden, kontrol edilen veya ortak kontrolde
                            olan bir varlık anlamına gelir; "kontrol", yöneticilerin veya diğer yönetim yetkililerinin
                            seçiminde oy kullanma hakkına sahip olan hisse senetlerinin, özkaynakların veya diğer
                            oy verme hakkı bulunan menkul kıymetlerin %50'sinden fazlasına sahip olmayı ifade eder.
                        </li>
                        <li>
                            <strong>Şirket</strong>: Bu Anlaşma'da "Şirket", "Biz", "Bize" veya "Bizim" olarak
                            anılan, Ra Digital LLC'yi ifade eder.
                        </li>
                        <li>
                            <strong>Aygıt</strong>: Bilgisayar, cep telefonu veya dijital tablet gibi Servise erişebilen
                            herhangi bir cihazı ifade eder.
                        </li>
                        <li>
                            <strong>Servis</strong>: Web sitesine atıfta bulunur.
                        </li>
                        <li>
                            <strong>Kullanım Koşulları</strong>: ("Koşullar" olarak da anılacaktır) Servisin kullanımı
                            ile ilgili olarak Siz ve Şirket arasındaki tam anlaşmayı oluşturan bu Kullanım Koşullarıdır.
                        </li>
                        <li>
                            <strong>Üçüncü Taraf Sosyal Medya Hizmeti</strong>: Servis tarafından gösterilebilecek,
                            dahil edilebilecek veya kullanılabilecek üçüncü bir taraf tarafından sağlanan hizmet veya
                            içerik (veri, bilgi, ürün veya hizmetler dahil) anlamına gelir.
                        </li>
                        <li>
                            <strong>Web Sitesi</strong>: radigital.io'dan erişilebilen Ra Digital'e atıfta bulunur.
                        </li>
                        <li>
                            <strong>Siz</strong>: Servise erişen veya kullanan bireyi veya uygun olduğunda bu bireyin
                            adına erişen veya kullanan şirketi veya diğer yasal varlığı ifade eder.
                        </li>
                    </ul>
                </section>

                <section className="mt-8 flex flex-col gap-2">
                    <h2 className="text-xl font-bold mb-2 ">Kabul</h2>

                    <p>
                        Bu Hizmetin kullanımını düzenleyen Kullanım Koşulları ve Şirket arasında işletme yürüten
                        anlaşmadır. Bu Kullanım Koşulları, Hizmetin kullanımıyla ilgili tüm kullanıcıların haklarını
                        ve yükümlülüklerini belirler.
                    </p>

                    <p>
                        Hizmete erişiminiz ve kullanımınız, bu Kullanım Koşullarını kabul etmenize ve bunlara uymanıza
                        bağlıdır. Bu Kullanım Koşulları, Hizmeti erişen veya kullanan tüm ziyaretçiler, kullanıcılar ve
                        diğerlerini kapsar.
                    </p>

                    <p>
                        Hizmeti erişmeniz veya kullanmanız, bu Kullanım Koşullarına bağlı olmayı kabul ettiğiniz anlamına
                        gelir. Bu Kullanım Koşullarının herhangi bir bölümü ile aynı fikirde değilseniz, Hizmete
                        erişemezsiniz.
                    </p>

                    <p>
                        18 yaşın üzerinde olduğunuzu temsil ediyorsunuz. Şirket, 18 yaşın altındakilerin Hizmeti
                        kullanmasına izin vermez.
                    </p>

                    <p>
                        Hizmete erişiminiz ve kullanımınız aynı zamanda Şirketin Gizlilik Politikasını kabul etmenize ve
                        uymanıza bağlıdır. Gizlilik Politikamız, Hizmeti veya Web sitesini kullanırken kişisel bilgilerinizin
                        toplanması, kullanılması ve açıklanması ile ilgili politikalarımızı ve prosedürlerimizi
                        açıklar ve yasaların size tanıdığı gizlilik haklarını ve yasaların size nasıl koruma sağladığını
                        anlatır. Lütfen Gizlilik Politikamızı kullanmadan önce dikkatlice okuyunuz.
                    </p>
                </section>

                <section className="mt-8 flex flex-col gap-2">
                    <h2 className="text-xl font-bold mb-2">Diğer Web Sitelerine Bağlantılar</h2>

                    <p>
                        Hizmetimiz, Şirket tarafından sahip olunmayan veya kontrol edilmeyen üçüncü taraf web
                        sitelerine veya hizmetlerine bağlantılar içerebilir.
                    </p>

                    <p>
                        Şirketin, herhangi bir üçüncü taraf web sitesinin veya hizmetinin içeriği, gizlilik politikaları
                        veya uygulamaları üzerinde herhangi bir kontrolü olmadığını ve hiçbir sorumluluk üstlenmediğini
                        ve ayrıca Şirketin, doğrudan veya dolaylı olarak, herhangi bir içerikten, malzemeden veya
                        hizmetten kaynaklanan veya iddia edilen herhangi bir hasardan veya kayıptan sorumlu olmadığını
                        açıkça kabul edersiniz. veya bu tür web siteleri veya hizmetler aracılığıyla mevcut.
                    </p>

                    <p>
                        Sizden, ziyaret ettiğiniz herhangi bir üçüncü taraf web sitesi veya hizmetinin koşullarını ve
                        gizlilik politikalarını okumanızı şiddetle tavsiye ederiz.
                    </p>
                </section>

                <section className="mt-8 flex flex-col gap-2">
                    <h2 className="text-xl font-bold mb-2">Sonlandırma</h2>

                    <p>
                        Herhangi bir nedenle, herhangi bir önceden bildirim veya yükümlülük olmaksızın, derhal erişiminizi
                        sonlandırabilir veya askıya alabiliriz, bu Kullanım Koşullarını ihlal ederseniz.
                    </p>

                    <p>Sonlandırma durumunda, Servisi kullanma hakkınız hemen sona erecektir.</p>
                </section>

                <section className="mt-8 flex flex-col gap-2">
                    <h2 className="text-xl font-bold mb-2">Sınırlı Sorumluluk</h2>

                    <p>
                        Hangi zararlardan kaynaklanırsa kaynaklansın, Şirketin ve tedarikçilerinin bu Koşulların herhangi
                        bir hükmü altında alabileceğiniz zararların tümü ve tümü için toplam sorumluluğu, Servis aracılığıyla
                        gerçekten ödediğiniz tutarla sınırlı olacaktır veya Hizmet aracılığıyla hiçbir şey satın almadıysanız
                        100 USD'dir.
                    </p>

                    <p>
                        Mevcut yasaların izin verdiği maksimum ölçüde, Şirket veya tedarikçileri, Servis, üçüncü taraf
                        yazılım ve / veya Servis ile kullanılan üçüncü taraf donanım kullanımı, performans veya güvenilirlik
                        standartlarını karşılamama, kesintisiz çalışma, performansın kesintisizliği, veya hata olmaksızın
                        çalışma garantisi vermez veya işbu Şartlarla bağlantılı olarak ve bir esas işlevi başarısız olursa
                        dahi, tüm özel, dolaylı veya sonuçsal zararlar (ancak bunlarla sınırlı olmamak üzere, kâr kaybı,
                        veri kaybı veya diğer bilgiler, iş kesintisi, kişisel yaralanma, Gizlilik kaybı, Hizmetin kullanımı
                        veya Hizmetle bağlantılı olarak veya bu Şartların herhangi bir hükmüyle ilgili olup olmadığı
                        fark etmeksizin, hata olmaması veya sağlayıcıların esaslı amacının başarısızlığı) için hiçbir
                        sorumluluk kabul etmez.
                    </p>

                    <p>
                        Bazı eyaletler, belirli garanti türlerinin hariç tutulmasına veya dolaylı veya ardışık zararların
                        sınırlandırılmasına izin vermez, bu nedenle yukarıdaki bazı sınırlamalar sizin için geçerli olmayabilir.
                        Bu eyaletlerde, her bir tarafın sorumluluğu yürürlükteki yasalarca izin verilen en büyük ölçüde
                        sınırlı olacaktır.
                    </p>
                </section>

                <section className="mt-8 flex flex-col gap-2">
                    <h2 className="text-xl font-bold mb-2">"OLDUĞU GİBİ" ve "MÜMKÜN OLDUĞUNCA" Reddi</h2>

                    <p>Hizmet size "OLDUĞU GİBİ" ve "MÜMKÜN OLDUĞUNCA" ve tüm kusurlar ve kusurlarla ve herhangi bir
                        garanti olmadan ve bu koşullara uygunlukla ve belirli bir amaca uygunluk, başlık ve ihlal etmeme
                        dahil olmak üzere, yasalara izin verilen maksimum ölçüde tüm garantilerden açıkça reddedilir. ,
                        ve işbu Şartların uygulanmasından kaynaklanabilecek sonuçlara ilişkin olarak sorumluluğu
                        kapsar. Uygulanabilir yasa uyarınca size verilen tüm zorunlu hükümlerden yararlanırsınız.</p>
                </section>

                <section className="mt-8 flex flex-col gap-2">
                    <h2 className="text-xl font-bold mb-2">Uygulanabilir Yasa</h2>

                    <p>Ülkenin yasaları, çatışma hükümlerinin dışında olmak üzere, bu Şartlar ve Hizmetinizin kullanımı
                        konusunda bu Şartlarca düzenlenmiştir. Uygulamanız, ayrıca yerel, eyalet, ulusal veya uluslararası
                        diğer yasalara tabi olabilir.</p>
                </section>

                <section className="mt-8 flex flex-col gap-2">
                    <h2 className="text-xl font-bold mb-2">Anlaşmazlıkların Çözümü</h2>

                    <p>Eğer Hizmetle ilgili bir endişeniz veya anlaşmazlığınız varsa, öncelikle Şirket ile iletişime geçerek
                        anlaşmazlığı gayri resmi olarak çözmeyi kabul edersiniz.</p>

                    <p>
                        <strong>Avrupa Birliği (AB) Kullanıcıları İçin</strong>
                    </p>

                    <p>
                        Eğer bir Avrupa Birliği tüketicisi iseniz, kaldığınız ülkenin yasalarının zorunlu hükümlerinden
                        faydalanacaksınız
                    </p>
                </section>

                <section className="mt-8 flex flex-col gap-2">
                    <h2 className="text-xl font-bold mb-2">Amerika Birleşik Devletleri Yasal Uyumu</h2>

                    <p>
                        (i) ABD hükümetinin ambargosuna tabi bir ülkede bulunmadığınızı veya ABD hükümeti tarafından
                        "teröre destek veren" bir ülke olarak belirlenmediğinizi ve (ii) ABD hükümetinin yasaklı veya
                        kısıtlı taraflar listesinde yer almadığınızı temsil ve taahhüt edersiniz.
                    </p>
                </section>

                <section className="mt-8 flex flex-col gap-2">
                    <h2 className="text-xl font-bold mb-2">Bölünebilirlik ve Feragat</h2>

                    <p>
                        <strong>Bölünebilirlik</strong>
                    </p>
                    <p>
                        Bu Şartların herhangi bir hükmü geçersiz veya uygulanamaz ise, bu hüküm mümkün olan en büyük ölçüde
                        yasaya uygun olarak değiştirilecek ve yorumlanacaktır ve kalan hükümler tam olarak yürürlükte
                        kalacaktır.
                    </p>

                    <p>
                        <strong>Feragat</strong>
                    </p>
                    <p>
                        Burada sağlananlar dışında, bir hak kullanmama veya bir yükümlülüğü yerine getirmeme durumu, bu
                        Koşulların ilerideki bir zamanda böyle bir hakkı kullanma veya böyle bir performansı isteme
                        yeteneğini etkilemeyecektir, ve ihlalin feragat edilmesi, sonraki herhangi bir ihlalin feragat
                        edilmesi anlamına gelmez.
                    </p>
                </section>

                <section className="mt-8 flex flex-col gap-2">
                    <h2 className="text-xl font-bold mb-2">Çeviri Yorumu</h2>

                    <p>Bu Kullanım Koşulları size Servisimizde mevcut olduğunda çevrildiyse kabul edersiniz.</p>
                    <p>Anlaşmazlık durumunda, orijinal İngilizce metin geçerli olacaktır.</p>
                </section>

                <section className="mt-8 flex flex-col gap-2">
                    <h2 className="text-xl font-bold mb-2">Bu Şartların ve Koşulların Değiştirilmesi</h2>

                    <p>
                        Tek taraflı olarak, bu Şartları istediğimiz zaman değiştirme veya değiştirme hakkını saklı
                        tutarız. Bir revizyon önemli ise, yeni koşulların yürürlüğe girmesinden en az 30 gün önce makul
                        çaba göstereceğiz. Bir değişikliğin neyin önemli olduğu, tek başımıza belirlenecektir.
                    </p>

                    <p>
                        Bu revizyonlar yürürlüğe girdikten sonra Hizmetimize erişmeye veya kullanmaya devam ettiğinizde,
                        revize edilen koşullara bağlı olmayı kabul edersiniz. Yeni koşulları, tamamen veya kısmen,
                        kabul etmiyorsanız, lütfen web sitesini ve Hizmeti kullanmayı durdurun.
                    </p>
                </section>

                <section className="mt-8 flex flex-col gap-2">
                    <h2 className="text-xl font-bold mb-2">Bize Ulaşın</h2>

                    <p>Bu Şartlar ve Koşullar hakkında herhangi bir sorunuz varsa, bize ulaşabilirsiniz:</p>

                    <p>
                        Web sitemizde bu sayfayı ziyaret ederek: <Link to="https://radigital.com.tr"><b>radigital.com.tr</b></Link>.
                    </p>
                </section>
            </div>
        </MainLayout>
    );
};
