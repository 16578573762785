import React from 'react'
import {Link} from "react-scroll";
export const ScoolButton = ({buttonText,buttonClassName,setOffset,link}) => {
    return (
        <Link to={link} smooth={true} duration={500} spy={true} offset={setOffset}>
        <button
            className={  buttonClassName ? buttonClassName :" backgroundGradient text-mainColor text-white font-medium py-2 px-4 rounded transition-all hover:bg-indigo-600 active:scale-95"}>
            {buttonText}
        </button>
        </Link>
    )
}
