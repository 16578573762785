import React from 'react';
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import website from "../../../Assets/Images/banner/website.jpg";
import {motion} from "framer-motion";
import {
    Timeline, TimelineBody, TimelineConnector, TimelineHeader, TimelineIcon, TimelineItem, Typography
} from "@material-tailwind/react";
import {PricingCard} from "../../../Components/Cards/pricingCard";
import bannersWebsite from "../../../Assets/Images/banners/Web Development.webp";

export default function Website() {
    const bannerHeader = "Olağanüstü Web Sitesi Geliştirme Hizmetleri"
    const bannerTxt = "Güçlü bir çevrimiçi varlık için Ra Digital'in web sitesi geliştirme hizmetlerini keşfedin. Uzman ekibimiz, etkileyici web siteleri tasarlayarak, katılımı ve dönüşümleri artıran sorunsuz kullanıcı deneyimleri sunuyoruz."
    return (

        <div>
            <ServiceDetailBanner bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={bannersWebsite}/>
            <section className="py-6">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className=" mx-auto flex flex-col justify-center items-center ">
                    <div className="flex flex-col justify-center items-center gap-3 px-6 max-w-6xl">
                        <Typography color="white" variant="h3" className="nbXs:text-center">
                            Dijital Dünyada Sizi Öne Çıkaracak Web Tasarımı
                        </Typography>
                        <Typography color="white" variant="lead">
                            İnternet, günümüzde işletmelerin vazgeçilmez bir pazarlama aracı haline geldi. Ancak sadece
                            bir web sitesine sahip olmak yeterli değil, o siteyi etkili bir şekilde tasarlamak ve
                            optimize etmek de önemli. İşte bu noktada profesyonel bir web tasarımı ve SEO uyumluluğu
                            devreye giriyor.
                        </Typography>
                    </div>
                </motion.div>
            </section>

            <section className="py-6">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className=" mx-auto flex flex-col justify-center items-center ">
                    <div className="flex flex-col justify-center items-center gap-3 px-6 max-w-6xl">
                        <img src={website} alt="website development" className="rounded-md"/>
                    </div>
                </motion.div>
            </section>

            <section className="py-6 flex justify-center ">
                <div className="xl:w-[70%] lg:w-[65%] md:w-[65%] p-8 pt-2 shadow-basicShadow">                    <Typography color="white" variant="h4" className="nbXs:text-center pb-3">
                        Web Sitesi Hizmetimize Neler Dahil?
                    </Typography>
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    1
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Web Tasarım ve Geliştirme
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Hedef kitlenizi görsel olarak etkileyici bir çevrimiçi deneyime dahil edin. Uzman
                                    tasarımcılarımız ve geliştiricilerimiz, marka estetiğinizi sergileyen ve aynı
                                    zamanda sorunsuz bir kullanıcı deneyimi için işlevselliği öncelikli kılan web
                                    siteleri oluşturmak için işbirliği yapar.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    2
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    E-Ticaret Çözümleri
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Özel e-ticaret çözümlerimizle çevrimiçi satış potansiyelinizi en üst düzeye çıkarın.
                                    Yeni bir çevrimiçi mağaza başlatıyor ya da mevcut bir mağazayı geliştiriyorsanız,
                                    hizmetlerimiz kullanıcı katılımını optimize etmek, dönüşümleri artırmak ve geliri
                                    maksimize etmek üzere tasarlanmıştır.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    3
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Tüm Cihazlara Uyumlu Tasarım
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Mobil gezinmenin hakim olduğu bir dünyada duyarlı tasarımı önceliklendiriyoruz. Web
                                    sitelerimiz, masaüstü bilgisayarlardan tablet ve akıllı telefonlara kadar cihazlar
                                    arasında optimal bir görüntüleme ve etkileşim deneyimi sağlar.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    4
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Performans Odaklı Geliştirme
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Harika görünmenin ötesinde olağanüstü performans sunan web siteleri deneyimleyin.
                                    Odak noktamız estetikten daha fazlasını sunarak, kullanıcı deneyiminde sorunsuz bir
                                    deneyim sağlamak ve katılımı ve işlevselliği maksimize etmektedir.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                    </Timeline>
                </div>
            </section>

            <PricingCard/>

            <section
                className="bg-gradient-to-r from-[#64748b] via-[#414e63] to-[#202c3e] py-8 rounded-md shadow-2xl mb-6">
                <div className="flex flex-col justify-center items-center gap-3 lg:px-6 px-2 max-w-6xl mx-auto">
                    <Typography color="white" variant="lead"
                                className="text-center nbXs:text-center pb-3 italic lg:text-xl text-lg nbXs:text-base">
                        "Ra Digital ile markanızı çevrimiçi olarak yükseltin, tasarımın sihirli bir dokunuş alacağı bir
                        dünyada! Kullanıcı odaklı büyücülüğümüz iş büyümesini besler, dijital başarıyı keyifli bir
                        yolculuğa dönüştürür. Yeniliğin tasarımla buluştuğu eğlenceli dünyamıza katılın ve Ra Digital
                        ile markanıza biraz web mükemmellik sihri serpin!"
                    </Typography>
                </div>
            </section>
        </div>


    )
}