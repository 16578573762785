import React from 'react'
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import {motion} from "framer-motion";
import {
    Timeline, TimelineBody, TimelineConnector, TimelineHeader, TimelineIcon, TimelineItem, Typography
} from "@material-tailwind/react";
import improve from "../../../Assets/Images/story/improve.png";
import bannersShopify from "../../../Assets/Images/banners/Shopıfy Market Management.webp";

export default function LinkedinAds() {
    const bannerHeader = "LinkedIn Ads: İşletmenizin Profesyonel İletişimdeki Gücü";
    const bannerTxt = "LinkedIn Ads ile işletmenizin profesyonel ağda daha fazla görünürlük kazanmasını sağlayın."
    return (

        <div>
            <ServiceDetailBanner bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={bannersShopify}/>
            <section className="pt-6">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className=" mx-auto flex flex-col justify-center items-center ">
                    <div className="flex flex-col justify-center items-center gap-3 px-6 max-w-6xl">
                        <Typography color="white" variant="h3" className="nbXs:text-center">
                            LinkedIn Ads Stratejileri
                        </Typography>
                        <Typography color="white" variant="paragraph">
                            LinkedIn, iş dünyasında profesyonel ilişkiler kurmak ve işletmelerin büyümesini desteklemek
                            için önemli bir platformdur. LinkedIn Ads kullanarak, hedef kitlenize odaklanabilir, marka
                            bilinirliğinizi artırabilir ve işletmenizin profesyonel iletişimdeki gücünü ortaya
                            koyabilirsiniz. İşte LinkedIn Ads'in sunduğu fırsatları en iyi şekilde değerlendirebilmek
                            için izlemeniz gereken stratejiler:
                        </Typography>
                    </div>
                </motion.div>
            </section>
            <section className="py-6 flex justify-center ">
                <div className="xl:w-[70%] lg:w-[65%] md:w-[65%] p-8 pt-2 shadow-basicShadow">
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    1
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Hedef Kitlenin Belirlenmesi ve Segmentasyon
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            LinkedIn Ads kampanyalarınızın etkili olabilmesi için öncelikle hedef
                                            kitlenizi doğru bir şekilde belirlemeniz ve segmentasyon yapmanız
                                            gerekmektedir. Endüstri, iş pozisyonu, şirket büyüklüğü gibi kriterleri
                                            kullanarak hedef kitlenizi daha spesifik bir şekilde tanımlayabilir ve
                                            reklamlarınızı doğrudan bu kişilere yönlendirebilirsiniz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    2
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    İçerik Kalitesi ve Reklam Formatları
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            LinkedIn kullanıcıları genellikle profesyonel içeriklere ve iş odaklı
                                            bilgilere ilgi gösterirler. Bu nedenle, LinkedIn Ads kampanyalarınızda
                                            kaliteli içerikler ve işletmenizin profesyonel kimliğini yansıtan reklam
                                            formatları kullanmalısınız. İşletmenizin güçlü yönlerini vurgulayan, çözüm
                                            odaklı ve dikkat çekici metinler ile hedef kitlenizin ilgisini
                                            çekebilirsiniz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    3
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Etkin Reklam Kampanyaları ve A/B Testleri
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            LinkedIn Ads platformunda etkin reklam kampanyaları oluşturarak markanızın
                                            görünürlüğünü artırabilirsiniz. A/B testleri yaparak farklı reklam
                                            formatlarını, başlık metinlerini ve görselleri test edebilir,
                                            performanslarını analiz ederek kampanyalarınızı sürekli olarak optimize
                                            edebilirsiniz. Böylece, en etkili reklam stratejilerini belirleyebilir ve
                                            bütçenizi en verimli şekilde kullanabilirsiniz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    4
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    İlgili ve Özgün Hedefleme
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            LinkedIn kullanıcıları genellikle işle ilgili konulara odaklanır ve
                                            profesyonel çevreleriyle etkileşimde bulunurlar. Bu nedenle, LinkedIn Ads
                                            kampanyalarınızda hedef kitlenizin ilgi alanlarına ve iş deneyimlerine uygun
                                            içerikler sunmalısınız. Özgün ve ilgi çekici mesajlar ile kullanıcıların
                                            dikkatini çekebilir ve etkileşimi artırabilirsiniz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    5
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Veri Analizi ve Optimizasyon
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            LinkedIn Ads kampanyalarınızın performansını düzenli olarak analiz ederek,
                                            veri odaklı kararlar alabilir ve kampanyalarınızı sürekli olarak optimize
                                            edebilirsiniz. Dönüşüm oranlarınızı, tıklama sayılarınızı ve etkileşimleri
                                            izleyerek, reklam stratejilerinizi güçlendirebilir ve işletmenizin LinkedIn
                                            üzerindeki etkisini artırabilirsiniz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                        Neden Bizi Seçmelisiniz?
                    </Typography>
                    <div className="lg:px-6 lg:w-[55%] w-full">
                        <div className="flex flex-col justify-center items-center gap-3 w-full">
                            <Typography color="white" variant="h3"
                                        className="lg:block hidden text-center nbXs:text-center pb-3">
                                Neden Bizi Seçmelisiniz?
                            </Typography>
                            <Typography color="white" variant="paragraph">
                                <ul>
                                    <li className="text-justify">
                                        LinkedIn Ads, işletmelerin profesyonel iletişimdeki gücünü artırmalarını
                                        sağlayan güçlü bir pazarlama aracıdır. Yukarıda belirtilen stratejileri
                                        kullanarak LinkedIn Ads kampanyalarınızı başarıyla yönetebilir, işletmenizin
                                        profesyonel kimliğini güçlendirebilir ve hedeflerinize ulaşabilirsiniz.
                                    </li>
                                </ul>
                            </Typography>
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-full max-h-[325x] nbXs:max-h-max">
                        <img src={improve} alt="Radigital Linkedin Reklam Optimizasyonu"
                             className="w-full max-h-[325px] nbXs:max-h-max object-cover drop-shadow-xl xl:object-cover object-contain"/>
                    </div>
                </motion.div>
            </section>


        </div>


    )
}