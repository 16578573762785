import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        // Scroll to the top of the page on route change
        window.scrollTo(0, 0);
    }, [pathname]);

    return null; // This component doesn't render anything
}

//
// import { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
//
// export default function ScrollToTop() {
//     const { pathname } = useLocation();
//
//     useEffect(() => {
//         // Scroll to the top of the page with smooth scrolling on route change
//         window.scrollTo({
//             top: 0,
//             behavior: 'smooth',
//         });
//     }, [pathname]);
//
//     return null; // This component doesn't render anything
// }
