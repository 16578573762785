import Marquee from "react-fast-marquee";
import React from 'react'
import amazon from "../../Assets/Images/brandLogo/amazon.webp";
import amazonadvert from "../../Assets/Images/brandLogo/amazonadvert.webp";
import googleads from "../../Assets/Images/brandLogo/googleads.webp";
import etsy from "../../Assets/Images/brandLogo/etsy.webp";
import shopify from "../../Assets/Images/brandLogo/shopify.webp";
import walmart from "../../Assets/Images/brandLogo/walmart.webp";
import meta from "../../Assets/Images/brandLogo/meta.webp";
import website from "../../Assets/Images/brandLogo/website.webp";
import {Link} from "react-router-dom";
export const CustomMarque = () => {
    const addsArray = [
        {
            src: meta,
            link: "/services/meta-ads"
        }
        ,
        {
            src: amazon,
            link: "/services/amazon-dsp"
        }
        ,
        {
            src: amazonadvert,
            link: "/services/amazon-ads"
        }
        ,
        {
            src: googleads,
            link: "/services/google-ads"
        }
        ,
        {
            src: etsy,
            link: "/services/etsy-market-management"
        }
        ,
        {
            src: shopify,
            link: "/services/shopify-market-management"
        },
        {
            src: walmart,
            link: "/services/walmart-market-management"
        },
        {
            src: website,
            link: "/services/website-management"
        }

    ]
    return (
        <Marquee direction={"right"} pauseOnHover={true} speed={"30"} >
            <div
                className="flex items-center justify-between gap-12 py-4 w-full">
                {
                    addsArray.map((item) => {
                        return (
                            <Link to={item.link} className="">
                                <img className="w-44 h-44 object-contain hover:scale-105 transition-all duration-200 mx-4"
                                     src={item.src} alt={item.src}/>
                            </Link>
                        )
                    })
                }
            </div>
        </Marquee>
    )
}
