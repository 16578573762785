import React from 'react';
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import socialMediaIcons from "../../../Assets/Images/story/socialMediaIcons.svg";
import {
    Typography, Timeline, TimelineItem, TimelineConnector, TimelineHeader, TimelineIcon, TimelineBody
} from "@material-tailwind/react";
import {motion} from "framer-motion";
import bannersSocialMedia from "../../../Assets/Images/banners/Socıal Medıa.webp";

export default function SocialMediaManagement() {
    const bannerHeader = "Etkili Sosyal Medya Yönetimi ile Markanızı Öne Çıkarın";
    const bannerTxt = "Sosyal medya, modern pazarlamanın vazgeçilmez bir parçası haline geldi. Doğru stratejilerle yönetilen sosyal medya hesapları, markanızın görünürlüğünü artırır, müşteri sadakatini güçlendirir ve satışları artırır."
    return (
        <div>
            <ServiceDetailBanner bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={bannersSocialMedia}/>
            <motion.section
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.6}}
                className="bg-gradient-to-r from-[#64748b] via-[#414e63] to-[#202c3e] py-8 rounded-md shadow-2xl mb-6">
                <div className="flex flex-col justify-center items-center gap-3 lg:px-6 px-2 max-w-6xl mx-auto">
                    <Typography color="white" variant="h3" className="text-center nbXs:text-center">
                        Sosyal Medyaya Yönetimi Hizmetine Yaklaşımımız
                    </Typography>
                    <Typography color="white" variant="lead"
                                className="text-center nbXs:text-center pb-3 lg:text-xl text-lg nbXs:text-base">
                        Ra Digital ile marka varlığınızı sosyal medyada yükseltin. Sosyal Medya Yönetim hizmetimiz, sizi
                        çeşitli platformlarda izleyicilerinizle buluşturan stratejik ve etkileşimli bir yaklaşımı
                        garanti eder.
                    </Typography>
                </div>
            </motion.section>

            <section className="py-6 flex justify-center ">
                <div className="xl:w-[55%] lg:w-[50%] md:w-[65%] p-8 shadow-basicShadow">
                    <Typography color="white" variant="h4" className="nbXs:text-center pb-3">
                        Sosyal Medya Yönetiminin Temel Özellikleri
                    </Typography>
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    1
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Stratejik Planlama ve İçerik Oluşturma
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Sosyal medya stratejinizi belirlerken hedef kitlenizi göz önünde bulundurarak doğru
                                    platformlarda aktif olun. Her platformun dinamiklerini anlayarak özgün ve ilgi
                                    çekici içerikler oluşturun. Görseller, videolar, infografikler ve interaktif
                                    içerikler kullanıcıları etkilemenin en etkili yollarıdır.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    2
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Hedef Kitleye Uygun Hedefleme ve Reklam Stratejileri
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Reklam stratejileri belirlerken hedef kitlenizi dikkate alarak doğru hedefleme seçeneklerini kullanın. Demografik, coğrafi, ilgi alanları ve davranışsal hedefleme seçenekleriyle etkili reklam kampanyaları oluşturun. A/B testleri yaparak en etkili reklam formatlarını ve metinlerini belirleyin.

                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    3
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    İzleme ve Analiz
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Sosyal medya performansınızı düzenli olarak izleyin ve analiz edin. Kullanıcı etkileşimleri, takipçi büyümesi, yayın etkileşim oranları gibi metrikleri takip ederek stratejilerinizi optimize edin. Analiz raporlarıyla güçlü ve zayıf yönlerinizi belirleyerek doğru adımlar atın.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    4
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Müşteri İlişkileri Yönetimi ve İletişim
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Sosyal medya hesaplarınızı aktif bir şekilde yöneterek müşteri sorularına hızlı ve etkili yanıtlar verin. Olumlu ve olumsuz geri bildirimleri dikkate alarak marka itibarınızı güçlendirin. Müşteri memnuniyetini artırmak için kampanyalar düzenleyin ve sadık müşterilerinizi ödüllendirin.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    5
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    SEO Uyumlu İçerikler ve Etiketleme
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Sosyal medya paylaşımlarınızda SEO uyumlu içerikler kullanın ve etiketleme stratejileriyle içeriğinizi daha geniş kitlelere ulaştırın. Trend konuları takip ederek güncel ve ilgi çekici içerikler oluşturun. Hashtag kullanımıyla içeriğinizi daha kolay bulunabilir hale getirin.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                        Neden Ra Digital'i Sosyal Medya Yönetimi İçin Seçmelisiniz?
                    </Typography>
                    <div className="lg:px-6 lg:w-[55%] w-full">
                        <div className="flex flex-col justify-center items-center gap-3 w-full">
                            <Typography color="white" variant="h3"
                                        className="lg:block hidden text-center nbXs:text-center pb-3">
                                Neden Ra Digital'i Sosyal Medya Yönetimi İçin Seçmelisiniz?
                            </Typography>
                            <Typography color="white" variant="paragraph">
                                <ul className="list-disc">
                                    <li>
                                        Stratejik ve Etkili Çözümler: Hedeflerinize uygun stratejiler geliştirerek sosyal medya performansınızı maksimize ediyoruz.
                                    </li>
                                    <li>
                                        Uzmanlık ve Deneyim: Sosyal medya yönetimi konusunda deneyimli ekibimizle size en iyi hizmeti sunuyoruz.
                                    </li>
                                    <li>
                                        Uzmanlık ve Deneyim: Sosyal medya yönetimi konusunda deneyimli ekibimizle size en iyi hizmeti sunuyoruz.
                                    </li>
                                    <li>
                                        Sonuç Odaklı Çalışma: Dönüşümleri artırarak markanızın sosyal medya varlığını güçlendiriyoruz.
                                    </li>
                                </ul>
                            </Typography>
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-2/3 lg:max-h-[325x]">
                        <img src={socialMediaIcons} alt="Radigital Social Media"
                             className="w-full max-h-full nbXs:max-h-[275px] object-cover drop-shadow-xl xl:object-cover object-contain"/>
                    </div>
                </motion.div>
            </section>
        </div>
    );
}
