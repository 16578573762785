import React from 'react';
import formSuccess from '../../Assets/Images/story/formSuccess.svg';
import {Alert, Typography, Button} from "@material-tailwind/react";
import {Link} from "react-router-dom";

export default function FormSuccess() {

    function IconSolid() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="w-6 h-6">
                <path
                    d="M5.48 10.089l1.583-1.464c1.854.896 3.028 1.578 5.11 3.063 3.916-4.442 6.503-6.696 11.311-9.688l.516 1.186c-3.965 3.46-6.87 7.314-11.051 14.814-2.579-3.038-4.301-4.974-7.469-7.911zm14.407.557c.067.443.113.893.113 1.354 0 4.962-4.038 9-9 9s-9-4.038-9-9 4.038-9 9-9c1.971 0 3.79.644 5.274 1.723.521-.446 1.052-.881 1.6-1.303-1.884-1.511-4.271-2.42-6.874-2.42-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11c0-1.179-.19-2.313-.534-3.378-.528.633-1.052 1.305-1.579 2.024z"/>
            </svg>
        );
    }

    return (
        <div className="relative h-screen overflow-hidden">
            <img src={formSuccess} alt="" className="w-full h-full object-cover"/>
            <div
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-white w-full lg:w-auto md:w-2/3 md:px-0 px-6">
                <div className="relative z-10">
                    <Alert className="bg-[#ffffffe0]" variant="ghost" icon={<IconSolid/>}>
                        <Typography className="font-medium">
                            Form submitted successfully!
                        </Typography>
                        <ul className="mt-2 ml-2 list-inside list-disc">
                            <li>We will get back to you as soon as possible.</li>
                            <li>Thank you for your interest in our services.</li>
                            <li>Click the button below to go back to the home page.</li>
                        </ul>
                        <Link to="/" className="mt-4 w-full flex items-center justify-center">
                            <Button variant={"outlined"} color="lightBlue" ripple="light">
                                Go back to home
                            </Button>
                        </Link>
                    </Alert>
                </div>
            </div>
        </div>
    );
}
