import React from 'react'
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import {motion} from "framer-motion";
import {
    Timeline, TimelineBody, TimelineConnector, TimelineHeader, TimelineIcon, TimelineItem, Typography
} from "@material-tailwind/react";
import improve from "../../../Assets/Images/story/improve.png";
import bannersShopify from "../../../Assets/Images/banners/Shopıfy Market Management.webp";
export default function MarketplaceManagement() {
    const bannerHeader = "Çevrimiçi Pazar Yerleri Yönetimi: Markanızı Büyütün ve Satışlarınızı Artırın!";
    const bannerTxt = " Trendyol, Etsy, Walmart ve Shopify gibi platformlar, markanızın görünürlüğünü artırarak satışlarınızı artırmanın etkili yollarını sunuyoruz.";
    return (

        <div>
            <ServiceDetailBanner bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={bannersShopify}/>
            <section className="py-6">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className=" mx-auto flex flex-col justify-center items-center ">
                    <div className="flex flex-col justify-center items-center gap-3 px-6 max-w-6xl">
                        <Typography color="white" variant="h3" className="nbXs:text-center">
                            Çevrimiçi Pazar Yerleri Yönetiminin Avantajları:
                        </Typography>
                        <Typography color="white" variant="paragraph">
                            <ul className="list-disc pl-6">
                                <li>
                                    Geniş Müşteri Kitlesi: Platformların geniş kullanıcı tabanı, ürünlerinizi
                                    milyonlarca potansiyel müşteriye ulaştırır.
                                </li>
                                <li>
                                    Marka İmajı ve Güven: Profesyonel bir şekilde yönetilen mağazalar, marka imajınızı
                                    güçlendirir ve müşteri güvenini artırır.
                                </li>
                                <li>
                                    Dönüşüm ve Satış Artışı: Optimizasyon ve stratejik yönetim ile dönüşüm oranlarınızı
                                    artırarak satışlarınızı büyütürsünüz.
                                </li>
                                <li>
                                    Rekabetçi Fiyatlandırma: Pazardaki rekabeti izleyerek doğru fiyatlandırma
                                    stratejileri geliştirirsiniz.
                                </li>
                            </ul>
                        </Typography>
                    </div>
                </motion.div>
            </section>
            <section
                className="bg-gradient-to-r from-[#64748b] via-[#414e63] to-[#202c3e] py-8 rounded-md shadow-2xl mb-6">
                <div className="flex flex-col justify-center items-center gap-3 lg:px-6 px-2 max-w-6xl mx-auto">
                    <Typography color="white" variant="h3" className="text-center nbXs:text-center">
                        Hizmetlerimizle Çevrimiçi Pazar Yerlerini Yönetiyoruz
                    </Typography>
                    <Typography color="white" variant="lead"
                                className="text-center nbXs:text-center pb-3 italic lg:text-xl text-lg nbXs:text-base">
                        Çevrimiçi pazar yerleri yönetimi hakkında daha fazla bilgi almak ve satışlarınızı optimize etmek için bize ulaşın. Size özel çözümlerimizle büyümeye hazırız!
                    </Typography>
                </div>
            </section>
            <section className="py-6 flex justify-center ">
                <div className="xl:w-[55%] lg:w-[50%] md:w-[65%] p-8 shadow-basicShadow">
                    <Typography color="white" variant="h4" className="nbXs:text-center pb-3">
                        Hizmetlerimizle Çevrimiçi Pazar Yerlerini Yönetiyoruz:
                    </Typography>
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    1
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Ürün Listeleme ve Optimizasyon
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            Ürünlerinizi doğru kategorilere yerleştirerek ve optimize ederek daha fazla
                                            müşteriye ulaşırsınız.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    2
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Fiyatlandırma ve Rekabet Analizi
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            Pazardaki fiyatları takip ederek rekabetçi fiyatlandırma stratejileri
                                            belirleriz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    3
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Stok ve Envanter Yönetimi
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            Stok seviyelerinizi izleyerek müşteri memnuniyetini artırır ve satış
                                            kayıplarını minimize edersiniz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    4
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Müşteri İletişimi ve Geri Bildirim Yönetimi
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            Müşteri sorularına hızlı yanıtlar verir ve olumlu geri bildirimleri
                                            artırarak marka itibarınızı güçlendirirsiniz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                        Neden Bizi Seçmelisiniz?
                    </Typography>
                    <div className="lg:px-6 lg:w-[55%] w-full">
                        <div className="flex flex-col justify-center items-center gap-3 w-full">
                            <Typography color="white" variant="h3"
                                        className="lg:block hidden text-center nbXs:text-center pb-3">
                                Neden Bizi Seçmelisiniz?
                            </Typography>
                            <Typography color="white" variant="paragraph">
                                <ul className="list-disc pl-6">
                                    <li>
                                        Uzmanlık ve Deneyim: Çevrimiçi pazar yerleri yönetimi konusunda uzman ekibimizle
                                        size en iyi hizmeti sunuyoruz.
                                    </li>
                                    <li>
                                        Stratejik Yaklaşım: Her platformu ve ürünü benzersiz bir şekilde ele alır ve
                                        size özel stratejiler geliştiririz.
                                    </li>
                                    <li>
                                        Sonuç Odaklı Çalışma: Satışlarınızı artırarak büyümeye odaklanıyoruz.
                                    </li>
                                    <li>
                                        Müşteri Memnuniyeti: Müşterilerimizin başarısını ön planda tutarak uzun vadeli
                                        iş birlikleri kuruyoruz.
                                    </li>
                                </ul>
                            </Typography>
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-full max-h-[325x] nbXs:max-h-max">
                        <img src={improve} alt="Radigital Marketplace Yönetimi"
                             className="w-full max-h-[325px] nbXs:max-h-max object-cover drop-shadow-xl xl:object-cover object-contain"/>
                    </div>
                </motion.div>
            </section>


        </div>


    )
}