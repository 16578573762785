import React from 'react';
import MainLayout from "../../Layout/MainLayout";
const faqData = [
    {
        question: "Amazon PPC için optimal performans için hangi stratejiler kullanılabilir?",
        answer: "Amazon PPC stratejileri, detaylı anahtar kelime araştırması, ilgi çekici reklam metni, stratejik teklif verme ve sürekli performans analizi içerir. Manuel ve otomatik hedefleme yöntemlerinin karışımı kampanya etkinliğini artırabilir.",
    },
    {
        question: "Amazon DSP, kapsamlı bir reklam stratejisine nasıl katkıda bulunur?",
        answer: "Amazon DSP, web üzerindeki hedef kitleleri hedefleyerek Amazon dışında reklam erişimini artırır. Demografik, alışveriş davranışları ve ilgi alanları gibi gelişmiş hedefleme seçenekleri sunar ve reklamverenlere güçlü bir marka görünürlüğü için güçlü bir araç sağlar.",
    },
    {
        question: "Amazon'daki A+ İçerik, ürün dönüşümlerini nasıl etkiler?",
        answer: "A+ İçerik, etkileyici görseller ve detaylı bilgilerle ürün detay sayfalarını zenginleştirir. Müşteri güvenini artırabilir, ürün özelliklerini vurgulayabilir ve markanızı farklılaştırabilir, bu da nihai olarak daha yüksek dönüşüm oranlarına yol açabilir.",
    },
    {
        question: "Amazon ürün listeleri için etkili SEO'nun ana unsurları nelerdir?",
        answer: "Etkili Amazon SEO, arka plandaki anahtar kelimeleri optimize etmek, çekici madde noktaları oluşturmak, ilgili arama terimlerini kullanmak ve olumlu müşteri yorumları almak içerir. Listeleme optimizasyonuna bütünsel bir yaklaşım, arama görünürlüğünü ve satışları artırabilir.",
    },
    {
        question: "Amazon'da Marka Kaydı, marka korumaya nasıl katkı sağlar?",
        answer: "Amazon Marka Kaydı, marka sahiplerine fikri mülkiyetlerini korumak için araçlar sağlar. Marka içeriği kontrolü, potansiyel ihlallerin erken tespiti ve ek pazarlama özelliklerine erişim gibi avantajlar sunar.",
    },
    {
        question: "Başarılı bir Sosyal Medya Yönetimi için hangi unsurlar önemlidir?",
        answer: "Başarılı Sosyal Medya Yönetimi, içerik planlamayı, hedef kitleyle etkileşimi, veri analizini ve çeşitli platformların stratejik kullanımını içerir. Tutumlu bir marka varlığı oluşturmak ve hedef kitle ile anlamlı bağlantılar kurmak temel önem taşır.",
    },
    {
        question: "Amazon'da etkili sipariş karşılama için hangi lojistik yönetimi uygulamaları kullanılır?",
        answer: "Amazon'da etkili lojistik yönetimi, envanter planlamayı, sipariş işleme optimizasyonunu ve güvenilir nakliye ortaklarını seçmeyi içerir. Amazon FBA (Fulfillment by Amazon) uygulamak da sipariş karşılama sürecini hızlandırabilir.",
    },
    {
        question: "Walmart pazarında varlık optimize etmek için hangi stratejiler kullanılabilir?",
        answer: "Walmart'ta varlık optimize etme, stratejik ürün listeleme, rekabetçi fiyatlandırma ve Walmart'ın reklam platformundan faydalanmayı içerir. Performans metriklerini ve müşteri geri bildirimlerini düzenli olarak izlemek, sürekli başarı için önemlidir.",
    },
    {
        question: "Başarılı Etsy pazar yönetimi için hangi stratejiler uygulanabilir?",
        answer: "Başarılı Etsy pazar yönetimi, benzersiz ürün listeleri oluşturmayı, Etsy'nin arama algoritmasını kullanmayı ve etkili pazarlama stratejileri uygulamayı içerir. Mükemmel müşteri hizmeti ile olumlu bir itibar oluşturmak uzun vadeli başarının anahtarıdır.",
    },
    {
        question: "Trendyol'da varlık yönetimini etkin bir şekilde nasıl yapabiliriz?",
        answer: "Etkili Trendyol mağaza yönetimi, ürün listelerini optimize etmeyi, promosyonları kullanmayı ve mükemmel müşteri hizmeti sunmayı içerir. Pazar eğilimlerinden haberdar olmak ve stratejileri buna göre ayarlamak başarı için önemlidir.",
    },
    {
        question: "Başarılı Shopify pazar yönetimi için hangi stratejiler uygulanabilir?",
        answer: "Başarılı Shopify pazar yönetimi, çekici bir mağaza ön yüzü oluşturmayı, ürün listelerini optimize etmeyi ve pazarlama stratejileri uygulamayı içerir. Shopify'in yerleşik araçlarını ve uygulamalarını kullanmak, genel alışveriş deneyimini iyileştirebilir.",
    },
    {
        question: "Google Ads kampanya başarısı için hangi stratejiler etkilidir?",
        answer: "Etkili Google Ads kampanyaları, detaylı anahtar kelime araştırması, ilgi çekici reklam metni, stratejik teklif verme ve sürekli performans analizi içerir. Reklam uzantılarını kullanmak ve dönüşüm metriklerini izlemek kampanya performansını daha da optimize edebilir.",
    },
    {
        question: "İşletmeler Meta Ads'ı nasıl etkili bir şekilde yönetir ve optimize eder?",
        answer: "Meta Ads'ı etkili bir şekilde yönetmek, net kampanya hedefleri belirlemek, doğru kitleyi hedeflemek ve etkileyici reklam içeriği oluşturmayı içerir. Reklam performansını düzenli olarak analiz etmek ve veriye dayalı ayarlamalar yapmak başarı için önemlidir.",
    },
    {
        question: "E-ticarette dış pazarlama'nın önemi nedir?",
        answer: "Dış pazarlama, trafik kaynaklarını çeşitlendirerek tek bir platforma olan bağımlılığı azaltmak için önemlidir. Bu, sosyal medya pazarlaması, e-posta kampanyaları ve etkileyici işbirlikleri gibi stratejileri içerir ve Amazon listelerine trafik çekmek için kullanılır.",
    },
    {
        question: "D2C çözümlerinde web sitesi geliştirme için hangi hususlar önemlidir?",
        answer: "D2C çözümleri için web sitesi geliştirmek stratejik bir yaklaşım gerektirir; doğru teknoloji yığını (örneğin, React, HTML, CSS ve JavaScript) seçimi, kullanıcı dostu bir arayüz oluşturma ve güçlü güvenlik önlemleri uygulama dahil önemlidir.",
    },
];


export default function FAQ() {
    return (
        <MainLayout>
            <div className="py-10 px-8 max-w-5xl mx-auto flex flex-col md:flex-row gap-12 text-white">
                <div className="md:sticky md:top-44 flex flex-col text-left basis-1/2 h-max ">
                    <p className="inline-block font-semibold mb-4">Ra Digital FAQ</p>
                    <p className="sm:text-4xl text-3xl font-extrabold text-base-content">
                        Sıkça Sorulan Sorular
                    </p>
                </div>
                <ul className="basis-1/2">
                    {faqData.map((item, index) => (
                        <li key={index}>
                            <button
                                className="relative flex gap-2 items-center w-full py-5 text-base font-semibold text-left border-t border-base-content/10"
                                aria-expanded="false"
                                onClick={() => toggleFAQ(index)}
                            >
                                <span className="flex-1">{item.question}</span>
                                <svg className="flex-shrink-0 w-4 h-4 ml-auto fill-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                    <rect y="7" width="16" height="2" rx="1" className="transform origin-center transition duration-200 ease-out false"></rect>
                                    <rect y="7" width="16" height="2" rx="1" className="transform origin-center rotate-90 transition duration-200 ease-out false"></rect>
                                </svg>
                            </button>
                            <div
                                className="transition-all duration-300 ease-in-out max-h-0 overflow-hidden"
                                style={{ transition: "max-height 0.3s ease-in-out 0s", maxHeight: "0" }}
                            >
                                <div className="pb-5 leading-relaxed">
                                    <div className="space-y-2 leading-relaxed">{item.answer}</div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </MainLayout>

    );
}

function toggleFAQ(index) {
    const button = document.querySelectorAll('button[aria-expanded]')[index];
    const content = button.nextElementSibling;
    button.setAttribute("aria-expanded", button.getAttribute("aria-expanded") === "false" ? "true" : "false");
    content.style.maxHeight = button.getAttribute("aria-expanded") === "true" ? content.scrollHeight + "px" : "0";
}
