import MainLayout from "../../Layout/MainLayout";


export const ServicesSolution = () => {
    return (
        <MainLayout>
            <div className="container mx-auto px-4">
                <h1>Service Creative Solution</h1>
            </div>
        </MainLayout>
    )
}