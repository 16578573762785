import React from 'react';
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import {motion} from "framer-motion";
import {
    Timeline, TimelineBody,
    TimelineConnector,
    TimelineHeader,
    TimelineIcon,
    TimelineItem,
    Typography
} from "@material-tailwind/react";
import {FaRegLightbulb} from "react-icons/fa";
import {BsSpeedometer2} from "react-icons/bs";
import {MdOutlineAdsClick, MdOutlineAnalytics} from "react-icons/md";
import experts from "../../../Assets/Images/story/experts.png";
import bannersAds from "../../../Assets/Images/banners/Google ADS.webp";

export default function GoogleAds() {
    const bannerHeader = "Google Reklamlarıyla Etkinliğinizi Artırın";
    const bannerTxt = "Google Ads kampanyalarınızı stratejik bir şekilde yöneterek başarıyı açığa çıkarın. Dikkatli bir organizasyon, sürekli iyileştirme ve etkili reklam için maksimum görünürlük sağlıyoruz."
    return (
        <div>
            <ServiceDetailBanner bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={bannersAds}/>
            <section className="py-6">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className=" mx-auto flex flex-col justify-center items-center ">
                    <div className="flex flex-col justify-center items-center gap-3 px-6 max-w-6xl">
                        <Typography color="white" variant="h3" className="nbXs:text-center">
                            Google Ads Yönetimi: Dijital Pazarlama Stratejinizi Güçlendirin ve Hedeflerinize Ulaşın!
                        </Typography>
                        <Typography color="white" variant="lead">
                            Google Ads, dijital pazarlama dünyasında markaların hedef kitlelerine ulaşmasını sağlayan
                            güçlü bir araçtır. Doğru stratejilerle yönetilen Google Ads kampanyaları, markanızın
                            görünürlüğünü artırır, trafik oluşturur ve dönüşümleri artırarak hedeflerinize ulaşmanıza
                            yardımcı olur.
                        </Typography>
                    </div>
                </motion.div>
            </section>

            <section className="py-6 flex justify-center ">
                <div className="xl:w-[55%] lg:w-[50%] md:w-[65%] p-8 shadow-basicShadow">
                    <Typography color="white" variant="h4" className="nbXs:text-center pb-3">
                        Google Ads Yönetiminin Avantajları:
                    </Typography>
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="p-2">
                                    <FaRegLightbulb className="h-4 w-4"/>
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Stratejik Anahtar Kelime Planlama
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            Stratejik Planlama ve Anahtar Kelime Araştırması: Hedeflerinize uygun
                                            anahtar kelimeler belirleyerek stratejiler oluşturuyoruz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="p-2">
                                    <BsSpeedometer2 className="h-4 w-4"/>
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Etkili Reklam Oluşturma
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            Reklam Oluşturma ve Optimizasyon: Etkili reklam metinleri ve görselleri ile
                                            kullanıcıları çekerek dönüşümleri artırıyoruz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="p-2">
                                    <MdOutlineAnalytics className="h-4 w-4"/>
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Bütçe Yönetimi ve İzleme
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            Bütçe Yönetimi ve İzleme: Reklam bütçenizi optimize ederek en iyi sonuçları
                                            elde etmenizi sağlıyoruz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineHeader>
                                <TimelineIcon className="p-2">
                                    <MdOutlineAdsClick className="h-4 w-4"/>
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Performans Analizi ve Geliştirme
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            Performans Analizi ve Raporlama: Reklam performansınızı düzenli olarak
                                            izleyerek stratejilerinizi güncelliyor ve geliştiriyoruz.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                       Neden Bizi Tercih Etmelisiniz?
                    </Typography>
                    <div className="lg:px-6 lg:w-[55%] w-full">
                        <div className="flex flex-col justify-center items-center gap-3 w-full">
                            <Typography color="white" variant="h3"
                                        className="lg:block hidden text-center nbXs:text-center pb-3">
                                Neden Bizi Tercih Etmelisiniz?
                            </Typography>
                            <Typography color="white" variant="paragraph">
                                <ul className="list-disc pl-6">
                                    <li>
                                        Uzmanlık ve Deneyim: Google Ads konusunda uzman ekibimiz, yılların verdiği deneyimle size en etkili ve verimli hizmeti sunar.
                                    </li>
                                    <li>
                                        Stratejik Yaklaşım: Her bir kampanyayı benzersiz bir şekilde ele alır ve markanızın hedeflerine en uygun stratejileri geliştiririz.
                                    </li>
                                    <li>
                                        Sonuç Odaklı Çalışma: Dönüşüm odaklı stratejilerimizle reklam performansınızı maksimize eder ve yatırım getirinizi artırırız.
                                    </li>
                                    <li>
                                        Müşteri Memnuniyeti: Müşteri memnuniyeti bizim için önceliktir. Size özel çözümler sunarak başarınızı desteklemek için buradayız.
                                    </li>

                                </ul>
                            </Typography>
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-full max-h-[325x] nbXs:max-h-[200px]">
                        <img src={experts} alt="Google Reklam Optimizasyonu"
                             className="w-full max-h-[325px] nbXs:max-h-[200px] object-cover drop-shadow-xl"/>
                    </div>
                </motion.div>

            </section>
        </div>
    )
}