import React from 'react';
import MainLayout from "../../Layout/MainLayout";
import {Form} from "../../Components/ContactForm/Form";


export function Contact() {
    return (
        <MainLayout contact={false}>
            <Form contact={true}/>
        </MainLayout>
    );
}

