import MainLayout from "../../Layout/MainLayout";

export const ServicesAdvertising = () => {
    return (
        <MainLayout>
        <div className="container mx-auto px-4">
            <h1>Services Advertising</h1>
        </div>
        </MainLayout>
    )
}