import React from 'react'
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import {motion} from "framer-motion";
import {
    Timeline, TimelineBody, TimelineConnector, TimelineHeader, TimelineIcon, TimelineItem, Typography
} from "@material-tailwind/react";
import improve from "../../../Assets/Images/story/improve.png";
import bannersMetaAds from "../../../Assets/Images/banners/META ADS.webp";

export default function MetaAds() {
    const bannerHeader = "Meta Ads Yönetimi";
    const bannerTxt = "META Ads ile sosyal medya reklamcılığının potansiyelini keşfedin. Facebook ve Instagram gibi platformlarda uzmanlığımız, markanızı yükselten, hedef kitlenizi etkileyen ve anlamlı sonuçlar sunan etkili kampanyalar oluşturmamızı sağlar.";
    // "Explore the potential of social media advertising with META. Our expertise spans platforms like Facebook and Instagram, enabling us to craft impactful campaigns that elevate your brand, engage your audience, and deliver meaningful results.";

    return (

        <div>
            <ServiceDetailBanner bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={bannersMetaAds}/>
            <section className="py-6">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className=" mx-auto flex flex-col justify-center items-center ">
                    <div className="flex flex-col justify-center items-center gap-3 px-6 max-w-6xl">
                        <Typography color="white" variant="h3" className="nbXs:text-center">
                            Meta Ads Yönetiminin Avantajları
                        </Typography>
                        <Typography color="white" variant="lead">
                            Meta Ads, Facebook ve Instagram gibi platformları kapsayan güçlü bir dijital reklam ağıdır.
                            Doğru stratejilerle yönetilen Meta Ads kampanyaları, markanızın görünürlüğünü artırır, hedef
                            kitlenizi çeker ve dönüşümleri artırarak başarıya ulaşmanızı sağlar. Ra Digital olarak, META
                            Ads
                            uzmanlığımızı kullanarak, Facebook ve Instagram'da etkili kampanyalar oluşturuyoruz.
                        </Typography>
                    </div>
                </motion.div>
            </section>
            <section
                className="bg-gradient-to-r from-[#64748b] via-[#414e63] to-[#202c3e] py-8 rounded-md shadow-2xl mb-6">
                <div className="flex flex-col justify-center items-center gap-3 lg:px-6 px-2 max-w-6xl mx-auto">
                    <Typography color="white" variant="h3" className="text-center nbXs:text-center">
                        Uzman Meta Ads Hizmetleri
                    </Typography>
                    <Typography color="white" variant="lead"
                                className="text-center nbXs:text-center pb-3 italic lg:text-xl text-lg nbXs:text-base">
                        "Ra Digital'de, deneyimli ve sertifikalı META Ads uzmanlarından oluşan bir ekibiz. Endüstri
                        trendlerinin ve algoritma güncellemelerinin önünde yer alıyoruz. Facebook Ads ve Instagram Ads'e
                        odaklanarak, geniş bir deneyim sunuyor ve kampanyalarınızın sadece görünür olmasını değil, aynı
                        zamanda unutulmaz olmasını sağlıyoruz."
                    </Typography>
                </div>
            </section>
            <section className="py-6 flex justify-center ">
                <div className="xl:w-[55%] lg:w-[50%] md:w-[65%] p-8 shadow-basicShadow">
                    <Typography color="white" variant="h4" className="nbXs:text-center pb-3">
                        Meta Ads Yönetimi Hizmetlerimiz
                    </Typography>
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    1
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Stratejik Planlama ve Hedef Belirleme
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Hedeflerinize uygun stratejiler belirleyerek kampanyalar oluşturuyoruz.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    2
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Reklam Oluşturma ve Optimizasyon
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Etkili reklam metinleri, görseller ve hedefleme seçenekleri ile dönüşümleri
                                    artırıyoruz.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    3
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Bütçe Yönetimi ve İzleme
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Reklam bütçenizi optimize ederek en iyi sonuçları elde etmenizi sağlıyoruz.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    4
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Performans Analizi ve Raporlama
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Reklam performansınızı düzenli olarak izleyerek stratejilerinizi güncelliyor ve
                                    geliştiriyoruz.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                        Neden Bizi Seçmelisiniz?
                    </Typography>
                    <div className="lg:px-6 lg:w-[55%] w-full">
                        <div className="flex flex-col justify-center items-center gap-3 w-full">
                            <Typography color="white" variant="h3"
                                        className="lg:block hidden text-center nbXs:text-center pb-3">
                                Neden Bizi Seçmelisiniz?
                            </Typography>
                            <Typography color="white" variant="paragraph">
                                <ul className="list-disc pl-6">
                                    <li>
                                        Dijital Reklam Uzmanlığı: Meta Ads konusunda deneyimli ve uzman ekibimizle en
                                        etkili reklam stratejilerini sunuyoruz.
                                    </li>
                                    <li>
                                        Kişiye Özel Çözümler: Her markanın ihtiyaçları farklıdır, bu nedenle size özel
                                        çözümler sunarak başarıya ulaşmanızı sağlıyoruz.
                                    </li>
                                    <li>
                                        Sonuç Odaklı Yaklaşım: Dönüşüm odaklı stratejilerimizle reklam performansınızı
                                        maksimize eder ve yatırım getirinizi artırırız.
                                    </li>
                                    <li>
                                        Müşteri Memnuniyeti: Müşterilerimizin başarısı bizim için önemlidir ve uzun
                                        vadeli işbirlikleri kurmayı hedefliyoruz.
                                    </li>
                                </ul>
                            </Typography>
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-full max-h-[325x] nbXs:max-h-max">
                        <img src={improve} alt="Radigital Meta Ads"
                             className="w-full max-h-[325px] nbXs:max-h-max object-cover drop-shadow-xl xl:object-cover object-contain"/>
                    </div>
                </motion.div>
            </section>


        </div>


    )
}