import React from 'react'
import {ClockIcon} from "@heroicons/react/24/outline";
import {Link} from "react-router-dom";
import {Button, Typography} from "@material-tailwind/react";
import blog1 from "../../Assets/Images/blog/blog1.webp";
import blog2 from "../../Assets/Images/blog/blog2.webp";
import blog3 from  "../../Assets/Images/blog/blog3.webp"
import blog4 from  "../../Assets/Images/blog/googleAdsBlog.webp"
import blog5 from  "../../Assets/Images/blog/googleAdsBlog2.webp"

export const BlogCard = () => {

    const blogData = [
        {
            id: 1,
            title: "Amazon Reklamcılığının Temel İlkeleri: En Kapsamlı Rehberiniz",
            image: blog1,
            description: "Amazon reklamcılığı, e-ticaret başarısının kritik bir yönüdür ve en iyi sonuçlar için temel prensiplere dikkat gerektirir. Temel noktalara birlikte göz atalım",
            type: "Amazon",
            date: "01.09.2023"
        },
        {
            id:4,
            title:"Google Ads: Dijital Pazarlamada Başarıya Giden Yolun Temelleri",
            image:blog4,
            description:"Google Ads, dijital pazarlama dünyasında işletmelerin etkili bir şekilde hedef kitlelerine ulaşmalarını sağlayan önemli bir araçtır. Bu platformun doğru şekilde kullanılması, işletmelerin çevrimiçi varlıklarını geliştirmesi ve potansiyel müşterileri etkilemesi açısından kritiktir. İşte Google Ads'i başlangıç seviyesinde anlamak ve kullanmak için temel bilgiler:",
            type: "Google Ads",
            date: "26.04.2024"
        },
        {
            id: 2,
            title: "Amazon Listeleme Performansınızı Bu SEO İpuçlarıyla Artırın",
            image: blog2,
            description: "Amazon'un rekabetçi ortamında, ürün listelerinizi görünürlük ve satışlar için optimize etmek önemlidir. İşte Amazon listelerinizi geliştirmek için bazı SEO ipuçları",
            type: "SEO",
            date: "24.12.2024"
        },
        {
            id: 3,
            title: "Maksimum Sonuçlar İçin Amazon Sponsorlu Ürün Reklamlarınızı Optimize Edin",
            image: blog3,
            description: "Amazon reklamcılığı söz konusu olduğunda, Sponsorlu Ürün Reklamları, ürün görünürlüğünü artırmak ve satışları artırmak için bir oyun değiştiricidir. İşte Sponsorlu Ürün Reklamlarınızı maksimum etki için optimize etmenin bazı stratejileri",
            type: "Amazon",
            date: "07.01.2024"
        },
        {
            id:5,
            title:"İleri Seviye Stratejiler ve Uygulamalar",
            image:blog5,
            description:"Dijital pazarlama dünyasında, Google Ads'in etkili bir şekilde kullanılması şirketlerin büyümesi ve çevrimiçi varlıklarını güçlendirmesi için önemli bir faktördür. Bu yazıda, Google Ads'i ileri seviyede kullanmak isteyenler için stratejik yaklaşımlar ve uygulamalar ele alınacaktır.",
            type: "Google Ads",
            date: "28.04.2024"
        },
    ]

    return (
        <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16">
            <div className="border-b mb-5 flex justify-between text-sm">
                <div className="flex items-center pb-2 pr-2 border-b-2 border-indigo-600 uppercase">
                    <ClockIcon className="h-6 mr-3 text-white"/>
                    <Typography color="white" variant="h5" className="font-montserrat">Son Bloglar</Typography>
                </div>
                <Button size="sm" variant="filled" color="gray" className="normal-case">
                    Ortalama okuma süresi 6 dakika.
                </Button>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                {blogData.map((blog, index) => (
                    <div className="rounded overflow-hidden shadow-lg flex flex-col bg-gray-200" key={index}>
                        <div className="relative h-[350px]">
                            <Link to={`/resources/blog/${blog?.id}`}>
                                <img className="w-full h-full" src={blog?.image} alt={blog?.image}/>
                                <div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>
                            </Link>
                            <Button size="sm" variant="filled" className="text-xs absolute top-0 right-0  px-4 py-2 text-white mt-3 mr-3 hover:bg-mainColorSoft hover:text-gray-200 transition duration-300 ease-in-out">
                                {blog?.type}
                            </Button>
                        </div>
                        <div className="px-6 py-4 mb-auto">
                            <Link to={`/resources/blog/${blog?.id}`} className="font-medium text-lg inline-block hover:text-gray-700 transition duration-500 ease-in-out inline-block mb-2 truncate-text-2">
                                {blog?.title}
                            </Link>
                            <p className="text-gray-500 text-sm truncate-text-3">
                                {blog?.description}
                            </p>
                        </div>
                        <div className="px-6 py-3 flex flex-row items-center justify-between bg-gray-100">
                            <span className="py-1 text-xs font-regular text-gray-900 mr-1 flex flex-row items-center">
                                <ClockIcon className="h-5 mr-1"/>
                                <span className="ml-1">
                                    {blog?.date}
                                </span>
                            </span>
                            <Link to={`/resources/blog/${blog?.id}`} className="inline-block">
                                <Button size="sm" variant="text" className="flex items-center gap-2">
                                    İncele
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={2}
                                        stroke="currentColor"
                                        className="h-4 w-4"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                    </svg>
                                </Button>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
