import React from "react";
import "../customCss/parallaxStar.css";
import { BasicNav } from "../Components/Navbar/BasicNav";
import Marquee from "react-fast-marquee";
import NewFooter from "../Components/Footer/NewFooter";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import logo from "../Assets/Images/RaLogos/softLogo (4).png";

function MainLayout({ children, contact = true }) {
  return (
    <div>
      {/*<StickyNavbar/>*/}
      {/*<NavbarWithMegaMenu/>*/}
      {/*<ComplexNavbar/>*/}
      <Marquee className="bg-[#1e365b] py-1">
        <p className="text-lg text-white font-montserrat">
          {/* Kaçırmayın! Çevrimiçi mağazanızın ücretsiz bir uzman analizini alın. Dönüşümleri artırın ve daha hızlı büyüyün. */}
          Kaçırmayın! Çevrimiçi mağazanızın ücretsiz bir uzman analizini alın,
          dönüşümlerinizi artırın ve işinizi hızla büyütün. Detaylı bilgi için
          hemen
          <a href="tel:+905396055320" className="radigitalPhone font-bold text-white">
           {" "} +90 539 605 53 20{" "}
          </a>
          numaralı telefonu arayın veya
          <a
            href="mailto:marketing.radigital@gmail.com"
            className="font-bold text-white"
          >
           {" "} marketing.radigital@gmail.com{" "}
          </a>
          adresine e-posta gönderin.
        </p>
      </Marquee>
      <BasicNav />
      {/*<WhatsApp/>*/}
      {contact ? (
        <div>
          <div id="stars"></div>
          <div id="stars2"></div>
          <div id="stars3"></div>
        </div>
      ) : (
        <div>
          <div id="starss"></div>
          <div id="starss2"></div>
          <div id="starss3"></div>
        </div>
      )}
      {/*if contact= true min-50vh else min-100vh*/}
      <div className={contact ? "min-h-[100vh] lg:px-0" : "h-max lg:px-0"}>
        {children}
      </div>
      {/*<ScrollTop/>*/}
      {/*<SpeedDialWithTextOutside/>*/}
      <FloatingWhatsApp
        avatar={logo}
        chatMessage="Merhaba Ra Digital'e hoş geldiniz! Size nasıl yardımcı olabilirim?"
        placeholder="Mesajınızı yazın..."
        statusMessage="Ra Digital WhatsApp üzerinden size yardımcı olabilir."
        darkMode={true}
        allowClickAway={true}
        allowEsc={true}
        chatboxHeight={350}
        notification={true}
        notificationDelay={60}
        // notificationSound={true}
        // notificationSoundSrc={'https://www.soundjay.com/button/beep-07.wav'}
        accountName={"Ra Digital"}
        phoneNumber={"+905396055320"}
        message={"Merhaba! Daha fazla bilgi almak istiyorum."}
      />
      {/*<Footer className="fixed bottom"/>*/}
      <NewFooter contact={contact} className="fixed bottom" />
    </div>
  );
}

export default MainLayout;
