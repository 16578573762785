import React from 'react';
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import brandRegistryBanner from "../../../Assets/Images/story/brandRegistryBanner.svg";
import brandRegistryQuestion from "../../../Assets/Images/story/brandRegistryQuestion.svg";
import {
    Typography, Timeline, TimelineItem, TimelineConnector, TimelineHeader, TimelineIcon, TimelineBody
} from "@material-tailwind/react";
import {motion} from "framer-motion";
import bannersBrand from "../../../Assets/Images/banners/Brand Registry.webp";

export default function BrandRegistry() {
    const bannerHeader = "Kolaylaştırılmış Çözümlerle Amazon Markanızı Büyütün";
    const bannerTxt = "Amazon'ın Marka Kaydı'nda gezinmek karmaşık olabilir. Ra Digital, marka varlığınızı koruyarak ve yükselterek basitleştirir.";
    return (
        <div>
            <ServiceDetailBanner bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={bannersBrand}/>
            <motion.section
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.6}}
                className="bg-gradient-to-r from-[#64748b] via-[#414e63] to-[#202c3e] py-8 rounded-md shadow-2xl mb-6">
                <div className="flex flex-col justify-center items-center gap-3 lg:px-6 px-2 max-w-6xl mx-auto">
                    <Typography color="white" variant="h3" className="text-center nbXs:text-center">
                        Marka Kaydı Yardımı Yaklaşımımız
                    </Typography>
                    <Typography color="white" variant="h4"
                                className="text-center nbXs:text-center pb-3 lg:text-xl text-lg nbXs:text-base">
                        Amazon'un Marka Kaydı'nda seyir etmenin karmaşıklığını Ra Digital ile basitleştirin. Uçtan uca
                        yardımımız sadece markanızı korumakla kalmaz, aynı zamanda kayıt avantajlarını kullanarak marka
                        varlığınızı yükseltir.
                    </Typography>
                </div>
            </motion.section>

            <section className="py-6 flex justify-center ">
                <div className="xl:w-[55%] lg:w-[50%] md:w-[65%] p-8 shadow-basicShadow">
                    <Typography color="white" variant="h4" className="nbXs:text-center pb-3">
                        Key Features of Brand Registry Assistance
                    </Typography>
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    1
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Kapsamlı Başvuru Desteği
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            Başvuru sürecinde kapsamlı desteğimizle sorunsuz ve başarılı bir kayıt
                                            süreci sağlayın.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    2
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Marka Kaydı Özelliklerinin Stratejik Kullanımı
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            Marka Kaydı özelliklerinden faydalanarak marka hikayenizi ve müşteri
                                            deneyimini geliştirmek için stratejik tavsiyeler alın.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    3
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Önleyici Marka Koruma Stratejileri
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    <ul className="list-disc pl-6">
                                        <li>
                                            Marka bütünlüğünüzü koruyarak sahte ürünlerle ve yetkisiz satıcılarla
                                            mücadele etmek için önceden önlem alan marka koruma stratejileri.
                                        </li>
                                    </ul>
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                        Ra Digital'i Marka Kaydı Yardımı için Neden Tercih Etmelisiniz?
                    </Typography>
                    <div className="flex md:flex-row flex-col justify-center items-center gap-3 w-full">
                        <div className="lg:px-6 lg:w-[55%] md:w-2/3 w-full">
                            <div className="flex flex-col justify-center items-center gap-3 w-full">
                                <Typography color="white" variant="h3"
                                            className="lg:block hidden text-center nbXs:text-center pb-3">
                                    Ra Digital'i Marka Kaydı Yardımı için Neden Tercih Etmelisiniz?
                                </Typography>
                                <Typography color="white" variant="paragraph">
                                    <ul className="list-disc">
                                        <li>
                                            Uzman Rehberlik: Marka Kaydı başvuru süreci boyunca uzman rehberliğin
                                            avantajlarından yararlanın ve başarılı bir kayıt sağlayın.
                                        </li>
                                        <li>
                                            Stratejik Bakış Açısı: Marka Kaydı özelliklerinin potansiyelini en üst
                                            düzeye çıkarmak için stratejik tavsiyeler alın, marka hikayenizi ve müşteri
                                            deneyimini geliştirin.
                                        </li>
                                        <li>
                                            Marka Bütünlüğü: Önceden önlem alan marka koruma stratejilerimiz, sahte
                                            ürünlerle ve yetkisiz satıcılarla mücadele ederek marka bütünlüğünüzü korur.
                                        </li>
                                    </ul>
                                </Typography>
                            </div>
                        </div>
                        <div className="lg:w-[45%] w-2/3 lg:max-h-[325x]">
                            <img src={brandRegistryQuestion} alt="brand-registry-protection"
                                 className="lg:w-full lg:h-full h-1/2 object-contain drop-shadow-xl"/>
                        </div>
                    </div>
                </motion.div>
            </section>
        </div>
    );
}
